<template>
  <form>
  <div class="bloco" style="background-color: #607D8B;  padding: 10px;  border-radius: 10px;">
    <div class="row">
      <div class="col-md-12 py-3">
  <select @change="onChangeVersoes($event)" v-model="versao" class="form-control">
    <option value="0" >Selecione uma versão</option> 
    <option v-for="v in $store.state.counter.versoes" :key="v.vrs_id.id" :value="v.vrs_id">
      {{ v.vrs_nome }}
    </option>
 </select>
</div>
<div class="col-6">
 <select @change="onChangeTestamentos($event)" v-model="testamento" class="form-control">
  <option value="0">Selecione um testamento</option> 
  <option v-for="v in $store.state.counter.testamentos" :key="v.tes_id" :value="v.tes_id">
     {{ v.tes_nome }}
   </option>
</select>
</div>
<div class="col-6">
 <select @change="onChangeLivros($event)" v-model="livro" class="form-control">
  <option value="0">Selecione um livro</option> 
  <option v-for="v in $store.state.counter.livros" :key="v.liv_id" :value="v.liv_id">
     {{ v.liv_nome }}
   </option>
</select>
</div>
<div class="col-md-12 py-3">
 <input type="text" v-on:blur="onSearch()" v-model="searchkey" placeholder="Termo de pesquisa" class="form-control"/>
</div>
</div>
<button type="submit" @click.prevent="onSearch()" class="btn btn-dark">Pesquisar</button>
</div>
</form>
<div class="col-md-12">
  <ul style="list-style: none;text-align: left;padding: 0;">
    <li v-for="v in $store.state.counter.search" :key="v.ver_id" :value="v.ver_id" style="margin:5px 0; padding: 10px 0; border-bottom: black 1px solid;">
      <h1>{{ this.getBook2(v.ver_liv_id,v.ver_id) }}</h1>  
      {{ v.ver_capitulo }}.{{ v.ver_versiculo }} - {{ v.ver_texto }}
    </li>
  </ul>
</div>  
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'Search',

  data() {
    return {
      versao:0,
      testamento:0,
      livro:0,
      capitulo:0,
      capitulos:0,
      searchkey:null,
      books:[],
      ids:[],
    };
  },

  created() {
    const versoes = this.$store.dispatch('counter/getVersoes', { type: 'INCREMENT', value: 1 })
  },

  computed: {
    ...mapState({
      counter: state => state.counter.counter
    })
  },

  methods: {
    ...mapMutations('counter', {
      $_add: 'INCREMENT',
      $_remove: 'DECREMENT',
    }),

    ...mapActions('counter', {
      $_counter: 'counter'
    }),

    getBook(book) {
      if (book == 1) {
        if (!this.books.includes(book)) {
          this.books.push(book);
          return true;
        } else {
          return false;
        }
      }
    },

    getBook2(book,id) {
      if (!this.books.includes(book)) {
         this.books.push(book);
         if (!this.ids.includes(id)) this.ids.push(id);
      }
      if (this.ids.includes(id) && book == 1) return 'Gênesis';
      if (this.ids.includes(id) && book == 2) return 'Êxodo';
      if (this.ids.includes(id) && book == 3) return 'Levítico';
      if (this.ids.includes(id) && book == 4) return 'Números';
      if (this.ids.includes(id) && book == 5) return 'Deuteronômio';
      if (this.ids.includes(id) && book == 6) return 'Josué';
      if (this.ids.includes(id) && book == 7) return 'Juízes';
      if (this.ids.includes(id) && book == 8) return 'Rute';
      if (this.ids.includes(id) && book == 9) return 'I Samuel';
      if (this.ids.includes(id) && book == 10) return 'II Samuel';
      if (this.ids.includes(id) && book == 11) return 'I Reis';
      if (this.ids.includes(id) && book == 12) return 'II Reis';
      if (this.ids.includes(id) && book == 13) return 'I Crônicas';
      if (this.ids.includes(id) && book == 14) return 'II Crônicas';
      if (this.ids.includes(id) && book == 15) return 'Esdras';
      if (this.ids.includes(id) && book == 16) return 'Neemias';
      if (this.ids.includes(id) && book == 17) return 'Ester';
      if (this.ids.includes(id) && book == 18) return 'Jó';
      if (this.ids.includes(id) && book == 19) return 'Salmos';
      if (this.ids.includes(id) && book == 20) return 'Provérbios';
      if (this.ids.includes(id) && book == 21) return 'Eclesiastes';
      if (this.ids.includes(id) && book == 22) return 'Cântico dos Cânticos';
      if (this.ids.includes(id) && book == 23) return 'Isaías';
      if (this.ids.includes(id) && book == 24) return 'Jeremias';
      if (this.ids.includes(id) && book == 25) return 'Lamentações de Jeremias';
      if (this.ids.includes(id) && book == 26) return 'Ezequiel';
      if (this.ids.includes(id) && book == 27) return 'Daniel';
      if (this.ids.includes(id) && book == 28) return 'Oséias';
      if (this.ids.includes(id) && book == 29) return 'Joel';
      if (this.ids.includes(id) && book == 30) return 'Amós';
      if (this.ids.includes(id) && book == 31) return 'Obadias';
      if (this.ids.includes(id) && book == 32) return 'Jonas';
      if (this.ids.includes(id) && book == 33) return 'Miquéias';
      if (this.ids.includes(id) && book == 34) return 'Naum';
      if (this.ids.includes(id) && book == 35) return 'Habacuque';
      if (this.ids.includes(id) && book == 36) return 'Sofonias';
      if (this.ids.includes(id) && book == 37) return 'Ageu';
      if (this.ids.includes(id) && book == 38) return 'Zacarias';
      if (this.ids.includes(id) && book == 39) return 'Malaquias';
      
      if (this.ids.includes(id) && book == 40) return 'Mateus';
      if (this.ids.includes(id) && book == 41) return 'Marcos';
      if (this.ids.includes(id) && book == 42) return 'Lucas';
      if (this.ids.includes(id) && book == 43) return 'João';
      if (this.ids.includes(id) && book == 44) return 'Atos';
      if (this.ids.includes(id) && book == 45) return 'Romanos';
      if (this.ids.includes(id) && book == 46) return 'I Coríntios';
      if (this.ids.includes(id) && book == 47) return 'II Coríntios';
      if (this.ids.includes(id) && book == 48) return 'Gálatas';
      if (this.ids.includes(id) && book == 49) return 'Efésios';
      if (this.ids.includes(id) && book == 50) return 'Filipenses';
      if (this.ids.includes(id) && book == 51) return 'Colossenses';
      if (this.ids.includes(id) && book == 52) return 'I Tessalonicenses';
      if (this.ids.includes(id) && book == 53) return 'II Tessalonicenses';
      if (this.ids.includes(id) && book == 54) return 'I Timóteo';
      if (this.ids.includes(id) && book == 55) return 'II Timóteo';
      if (this.ids.includes(id) && book == 56) return 'Tito';
      if (this.ids.includes(id) && book == 57) return 'Filemom';
      if (this.ids.includes(id) && book == 58) return 'Hebreus';
      if (this.ids.includes(id) && book == 59) return 'Tiago';
      if (this.ids.includes(id) && book == 60) return 'I Pedro';
      if (this.ids.includes(id) && book == 61) return 'II Pedro';
      if (this.ids.includes(id) && book == 62) return 'I João';
      if (this.ids.includes(id) && book == 63) return 'II João';
      if (this.ids.includes(id) && book == 64) return 'III João';
      if (this.ids.includes(id) && book == 65) return 'Judas';
      if (this.ids.includes(id) && book == 66) return 'Apocalipse';

    },

    onChangeVersoes(event) {
            this.$store.dispatch('counter/getTestamentos', { type: 'INCREMENT', value: 1 })
            this.testamento = 0;
    },

    onChangeTestamentos(event) {
            this.$store.dispatch('counter/getLivros', { testamento: this.testamento })
            this.livro = 0;
    },

    onChangeLivros(event) {
            this.$store.dispatch('counter/getCapitulos', { versao: this.versao, livro: this.livro })
            this.capitulo = 0;
    },

    onSearch(event) {
            this.$store.dispatch('counter/getSearch', { versao: this.versao, livro: this.livro, searchKey: this.searchkey })
    },

    checkForm: function (e) {
      if (this.versao && this.livro) {
        return true;
      }

      this.errors = [];

      if (!this.versao) {
        alert('nao');
        this.errors.push('A versão é obrigatória.');
      }
      if (!this.livro) {
        alert('nao');
        this.errors.push('O livro é obrigatório.');
      }

      e.preventDefault();
    }

  },
};
</script>
