import mutations from './mutations';
import actions from './actions'
import getters from './getters'

export default {
  namespaced: true,
  persist: true,
  state: () => ({
    counter: 0,
    area: 0,
    isEdit: false,
    hideaddress: true,
    url: '',
    site: '',
    root: { discount: 0, subtotal: 0, total: 0 },
    login: { id: 1, state : 'show', name: '', cpf: '', phone: '', email: ''},
    entrega: { id: 1, state : 'show', name: '', price: '0.00', delivery_time: ''},
    frete: { id: 1, state : 'show', name: '', price: '0.00', delivery_time: ''},
    pagamento: { id: 1, state : 'show', name: '', cpf: '', phone: '', email: ''},
    resumo: { id: 1, state : 'show', name: '', cpf: '', phone: '', email: ''},
    contact: null,
    contacts: [],
    newUser: null,
    contactId: null,
    pagseguro: {
      email: 'andreia_paim@hotmail.com',
      card: null,
      pix: null,
      bullet : null,
      parcelas: null,
      publicKeys: null,
      status: null,
      token: 'e32e62d6-99fa-4282-a2a5-bf071bff47f44d3bd7f24da29971c40afb5d6c84ebe64e1e-4d8a-440b-b3cc-562d42601207',
    },  
    categories: null, 
    category: null,
    versoes: null,
    testamentos: null,
    livros:null,
    versiculos:null,
    capitulos:null,
    search:null,
    postsv: [
      { id: 1, title: 'Hello' },
      { id: 2, title: 'Bye' },
    ],
  }),
  mutations,
  actions,
  getters
}
