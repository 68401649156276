<template>
  <div class="container">
    <Counter />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import Counter from '@/components/Counter';

export default {
  name: 'App',
  components: {
    Counter,
  },

  created() {
    const post = this.$_getPostById(1);
    //console.log(post.title);
  },

  computed: {
    ...mapState({
      firstName: state => state.users.first_name
    }),

    ...mapGetters('users', {
      $_fullName: 'fullName',
    }),

    ...mapGetters('posts', {
      $_getPostById: 'getPostById'
    }),

    userFirstName: {
      get() {
        return this.firstName
      },
      set(vl) {
        this.$_STORE_FIRST_NAME(vl)
      },
    },
  },

  methods: {
    ...mapMutations('users', {
      $_STORE_FIRST_NAME: 'STORE_FIRST_NAME'
    })
  },

}
</script>
