import httpClient from "@/services/HttpClient.js";

export default {
  counter({ commit }, { type, value }) {
    return new Promise((resolve) => {
      setTimeout(() => {
        commit(type, value)

        resolve()
      }, 3000)
    })
  },
  getAllCategories() {
    let endpoint =`https://mybrain.com.br/api/biblia/versiculos/1/20/1/1`;
    httpClient.get(endpoint).then((response) => {
      console.log(response);
      this.categories = response; 
    });
  },
  setArea({ commit }, { type, value }) {
    commit('SETArea', value);
  },
  
  setisEdit({ commit }, { type, value }) {
    commit('SETisEdit', value);
  },

  setRoot({ commit }, { type, value }) {
    commit('SETRoot', value);
  },

  setLogin({ commit }, { type, value }) {
    commit('SETLogin', value);
  },

  setContactAtual({ commit }, { type, value }) {
    commit('SETContactAtual', value);
  },

  setContact({ commit }, { type, value }) {
    commit('SETContact', value);
  },
  
  setContactSelected({ commit }, { type, value }) {    
    commit('SETContactSelected', value);
  },

  updateTotal({ commit }, { type, value }) {
    commit('UPDATETotal');
  },

  setFrete({ commit }, { type, value }) {
    commit('SETFrete', value);
  },

  setStatus({ commit }, { type, value }) {
    commit('SETStatus', value);
  },

  setParcelas({ commit }, { type, value }) {
    commit('SETParcelas', value);
  },

  setContactId({ commit }, { type, value }) {
    commit('SETContactId', value);
  },

  setHideAddress({ commit }, { type, value }) {
    commit('SETHideAddress', value);
  },

  async setEmail({ commit, state }, { type, value }) {
    //let endpoint =`${state.root.url}api/checkemail`;
    //await httpClient.post(endpoint,{email:value}).then((response) => {
      //commit('SETNewUser', response.data);
      //console.log(response.data);
      commit('SETEmail', value);
    //});    
  },

  setPagSeguroToken({ commit }, { type, value }) {
    commit('SETPagSeguroToken', value);
  },

  setName({ commit }, { type, value }) {
    commit('SETName', value);
  },

  setCpf({ commit }, { type, value }) {
    commit('SETCpf', value);
  },

  setPhone({ commit }, { type, value }) {
    commit('SETPhone', value);
  },

  setPersonalData({ commit }, { type, value }) {
    commit('SETPersonalData', value);
  },

  setState({ commit }, { type, value }) {

    switch(type) {
      case 'LOGIN':
        commit('SETStateLogin', value);
        break;
      case 'ENTREGA':
        commit('SETStateEntrega', value);
        break;
      case 'PAGAMENTO':
        commit('SETStatePagamento', value);
        break;
      default:
        // code block
    }
    
  },

  getContacts({ commit, state }, { type, value }) {
    let endpoint =`${state.root.url}api/address/${value}`;
    httpClient.get(endpoint).then((response) => {
      commit('SETContacts', response.data);
    });
  },

  getPublicKeys({ commit, state }) {
    var data = {
      'token' : state.pagseguro.token
    };

    let endpoint =`${state.root.url}api/pay/card/publickeys`;
    httpClient.post(endpoint,{body:data}).then((response) => {
      commit('SETPublicKeys', response.data.public_key);
      //console.log(response.data.public_key);
    });
  },

  async payCard({ commit, state }, { type, data }) {
    let endpoint =`${state.root.url}api/pay/card/ps`;

    data.token = state.pagseguro.token; 

    await httpClient.post(endpoint,data).then(
      (response) => {

        commit('SETCard', response.data);
    
        endpoint =`${state.root.url}checkoutapi`;
    
        let final = {};
        final.type = data;
        final.user = state.login;
        final.address = state.contact;
        final.delivery = state.frete;
        final.cart = state.root.cart;
        final.root = state.root;
        final.reference_id = response.data.charges[0].reference_id;
        final.status = response.data.charges[0].status;
        final.csrf_token = state.root.token; 
    
        httpClient.post(endpoint,final).then((response) => {
        });

  });

  },

  async payPix({ commit, state }, { type, data }) {
    let endpoint =`${state.root.url}api/pay/pix/ps`;

    data.token = state.pagseguro.token; 

    await httpClient.post(endpoint,data).then(
      (response) => {

        commit('SETPix', response.data);
    
        endpoint =`${state.root.url}checkoutapi`;
    
        let final = {};
        final.type = data;
        final.user = state.login;
        final.address = state.contact;
        final.delivery = state.frete;
        final.cart = state.root.cart;
        final.root = state.root;
        final.reference_id = response.data.reference_id;
        final.csrf_token = state.root.token; 
    
        httpClient.post(endpoint,final).then((response) => {
        });

  });

  },

  async payBullet({ commit, state }, { type, data }) {
    let endpoint =`${state.root.url}api/pay/pec/ps`;

    data.token = state.pagseguro.token; 

    await httpClient.post(endpoint,data).then(
      (response) => {

        commit('SETBullet', response.data);
    
        endpoint =`${state.root.url}checkoutapi`;
    
        let final = {};
        final.type = data;
        final.user = state.login;
        final.address = state.contact;
        final.delivery = state.frete;
        final.cart = state.root.cart;
        final.root = state.root;
        final.reference_id = response.data.charges[0].reference_id;
        final.status = response.data.charges[0].status;
        final.csrf_token = state.root.token; 
    
        httpClient.post(endpoint,final).then((response) => {
        });

  });

  },


  async authCorreios({ commit, state }, { type, data }) {
    let endpoint =`${state.root.url}api/authcorreios`;

    //data.token = state.pagseguro.token; 

    await httpClient.post(endpoint,data).then(
      (response) => {

        //commit('SETBullet', response.data);
    });

  },

  getVersoes({ commit, state }, { type, value }) {
    let endpoint =`${state.root.url}api/biblia/versoes`;
    httpClient.get(endpoint).then((response) => {
      this.versoes = response.data; 
      commit('SETVersoes', response.data);
    });
  },
  getTestamentos({ commit, state }, { type, value }) {
    let endpoint =`${state.root.url}api/biblia/testamentos`;
    httpClient.get(endpoint).then((response) => {
      this.versoes = response.data; 
      commit('SETTestamentos', response.data);
    });
  },
  getLivros({ commit, state }, { testamento }) {
    let endpoint =`${state.root.url}api/biblia/livros/${testamento}`;
    httpClient.get(endpoint).then((response) => {
      this.livros = response.data; 
      commit('SETLivros', response.data);
    });
  },
  getVersiculos({ commit, state }, { versao, livro, capitulo }) {
    let endpoint =`${state.root.url}api/biblia/versiculos/${versao}/${livro}/${capitulo}`;
    httpClient.get(endpoint).then((response) => {
      this.livros = response.data; 
      commit('SETVersiculos', response.data);
    });
  },
  getCapitulos({ commit, state }, { versao, livro }) {
    let endpoint =`${state.root.url}api/biblia/versiculos/count/${versao}/${livro}`;
    httpClient.get(endpoint).then((response) => {
      this.livros = response.data; 
      commit('SETCapitulos', response.data);
    });
  },
  getSearch({ commit, state }, { versao, livro, searchKey }) {
    let endpoint =`${state.root.url}api/biblia/versiculos/search/${versao}/${livro}/${searchKey}`;
    httpClient.get(endpoint).then((response) => {
      this.livros = response.data; 
      commit('SETSearch', response.data);
    });
  },
}
