<template>
    <div class="box-checkout box-addresses link-box-checkout" data-url="address" data-target=".box-addresses">
        <div class="box-title">
            <div class="holder-number">2</div><!-- /.holder-number -->
            <div class="title f-h2 ctx-title bold">
                Entrega
                                <i class="icon-complete ml5 fa fa-check green"></i>
                        </div><!-- /.f-h2 -->
            <div class="desc mb5 block">
                Cadastre ou selecione um endereço
            </div>
        </div><!-- /.box-title -->
                    <div class="infos black-80 f14">
                <span class="medium mb5 block">Endereço para entrega:</span>
                 {{this.contactAtual.street}}, {{this.contactAtual.number}} - {{this.contactAtual.district}} <br>
    
                                Complemento: {{this.contactAtual.complement}} <br>
                
                                {{`${this.contactAtual.city}-${this.contactAtual.state}`}} | CEP <span class="zipcode">{{this.contactAtual.postalcode}}</span> | Destinatário <span class="zipcode">{{this.contactAtual.recipient}}</span>
    
                                <span class="medium mb5 mt15 block">Forma de entrega:</span>
                    <span>
                        
                        {{ this.frete.name }}<br/>
                        Entrega em até {{ this.frete.delivery_time }} dias<br/>
                        Valor: R$ {{ this.frete.price.replace('.',',') }}
                        
                                        </span>
                            <div class="edit">
                    <span class="icon icon-pencil"></span>
                    <span class="btn-label -mobile -block bold f9 mt3 black-40">Editar</span>
                    <span class="-desktop">
                        <span class="tooltip2">
        <div class="tt-content-holder" style="width: auto">
            <div class="tt-content bold text-center">
                                <div class="tt-line">
                        Editar
                    </div>
                        </div>
        </div>
    </span>                </span>
                </div><!-- /.edit -->
            </div><!-- /.infos -->
        
    
            <div class="overlay-spinner overlay-spinner-box">
            <div class="spinner spinner-grey"></div><!-- /.spinner spinner-grey -->
        </div><!-- /.spinner-box -->
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
    export default {
        name: 'TodoCardEnd',

        props: {
            todo: {
                type: Object,
                default: () => ({}),
            },
        },

        created() {
        },

        computed: {
            ...mapState({
            contactAtual: state => state.counter.contact,    
            frete: state => state.counter.frete,    
            }),
        },

        data() {
            return {
                email: this.todo.email
            };
        },

        methods: {
            onUpdate() {
                this.$emit('update');
            },
        },
    };
</script>
