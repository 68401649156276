<template>
<div class="container container-promocode active-customer text-center">

  <div class="holder-cols-checkout">
      
    <TodoSteps/> 

      <div class="clearfix col-checkout-holder">
    
        <div class="col-checkout col-1">
                
          <TodoCard
          :key="login.id"
          :todo="login"
          />  
            
          <h1 v-if="this.area == 1" style="display:none;">Segunda parte</h1>

          <TodoCardEntrega
          :key="entrega.id"
          :todo="entrega"
          />

        </div><!-- /.col-checkout -->
  
        <TodoCardPagamento
        :key="pagamento.id"
        :todo="pagamento"
        />
  

        <TodoCardResumo
        :key="resumo.id"
        :todo="resumo"
        />

        <div class="col-checkout col-4">
        </div><!-- /.col-checkout -->
        
      </div><!-- /.clearfix -->	
    
  </div><!-- /.holder-cols-checkout -->
  
</div>
</template>

<script>
// @ is an alias to /src
import TodoCard from '@/components/Login/TodoCardLogin.vue';
import TodoCardEntrega from '@/components/Entrega/TodoCardEntrega.vue';
import TodoCardPagamento from '@/components/Pagamento/TodoCardPagamento.vue';
import TodoCardResumo from '@/components/Resumo/TodoCardResumo.vue';
import TodoSteps from '@/components/Steps/TodoCardSteps.vue';
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: "HomeView",
  components: {
    TodoCard,
    TodoCardEntrega,
    TodoCardPagamento,
    TodoCardResumo,
    TodoSteps  
  },

  props: {
				default:0,
        todo: {
            type: Object,
            default: () => ({}),
        },
        },

  data() {
    return {
        newTask: '',
        clientId: 0,
        discount: 0,
        cepId: 0,
        url: '',
        cart: [],
        message: '',
        spinner: {
            get_todo: true,
        },
    };
  },
 
  computed: {
    ...mapState({
      area: state => state.counter.area,
      login: state => state.counter.login,
      entrega: state => state.counter.entrega,      
      pagamento: state => state.counter.pagamento,      
      resumo: state => state.counter.resumo,      
    }),

  },

  methods: {

    ...mapMutations('counter', {
      $_add: 'INCREMENT',
      $_remove: 'DECREMENT',
    }),

    ...mapActions('counter', {
      $_counter: 'counter'
    }),

    verisso(event) {
      //console.log(this.$store.state.counter);
            //this.$store.dispatch('counter/setArea', { type: 'INCREMENT', value: 1 })
            console.log(this.$store.state.counter.area);
            console.log(this.area);
    },

  },

};
</script>
