<template>
    <div class="box-checkout box-customer active" data-target=".box-customer" data-url="checkout" data-next=".box-addresses" data-discount-alias="">
        <div class="box-title">
            <div class="holder-number">1</div><!-- /.holder-number -->
            <div class="title ctx-title f-h2 bold"> Identifique-se </div><!-- /.f-h2 -->
            <div class="desc mb20"> Utilizaremos seu e-mail para: Identificar seu perfil, histórico de compra, notificação de pedidos e carrinho de compras. </div>
        </div><!-- /.box-title -->

    <div class="box-content">
        <form action="javascript:" id="form-signup" class="form-horizontal clearfix text-left js-form-customer  form-customer-rebuy" data-method="POST" data-action="customers" data-gtm-form-interact-id="0">
          
            <div class="js-customer-form-inputs">
                                <div class="form-group w-100 form-group-static-mail -custom-margin">
                        <button @click.stop.prevent="onCancel()" class="btn btn-as-link btn-tertiary btn-xs btn-hp0 text-left trigger-close-customer-full-form">
                            <i class="fa fa-chevron-left f9 mr5 icon-change-mail"></i>Trocar e-mail
                        </button>
                        <div class="mt10 black-50">
                            <span class="mr5 icon-static-mail"><svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.125 11.375V4.375H15.875V11.375C15.875 12.8247 14.6997 14 13.25 14H2.75C1.30025 14 0.125 12.8247 0.125 11.375L0.125 4.375H1.875L1.875 11.375C1.875 11.8582 2.26675 12.25 2.75 12.25L13.25 12.25C13.7332 12.25 14.125 11.8583 14.125 11.375Z"></path>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.585164 6.02041C0.301743 5.8678 0.125001 5.5719 0.125001 5.25L0.125001 2.625C0.125001 1.17525 1.30025 -1.72881e-08 2.75 0L13.25 1.25211e-07C14.6997 1.42499e-07 15.875 1.17525 15.875 2.625V5.25C15.875 5.5719 15.6983 5.8678 15.4148 6.02041L8.91484 9.52041C8.78734 9.58906 8.6448 9.625 8.5 9.625H7.5C7.3552 9.625 7.21266 9.58906 7.08516 9.52041L0.585164 6.02041ZM1.875 4.72737L7.7206 7.875H8.2794L14.125 4.72737V2.625C14.125 2.14175 13.7332 1.75 13.25 1.75L2.75 1.75C2.26675 1.75 1.875 2.14175 1.875 2.625V4.72737Z"></path>
                            </svg>
                            </span>
                            <span class="js-customer-mail">{{this.todo.email}}</span>
                        </div>
                    </div>
                    <div class="form-group w-100 -custom-margin p-0 m-0">
                        <hr class="p-0 m-0">
                    </div>
                
                <div class="form-group w-100 form-person-type remove-margin">
          <input type="hidden" id="input-f" name="type" value="f" class="select-customer-type" data-target="group-f" checked="">
      </div><!-- /.form-group -->
    
                <div class="clearfix">
                    <div class="hp_field_wrap" style="display:none;"><input name="hp_field" type="text" value="" id="hp_field"><input name="hp_time" type="text" value="eyJpdiI6Ink0V3YybUQ5OWZ0K2ZMTTJBSmhcL2h3PT0iLCJ2YWx1ZSI6IkhTWlFwM2h5aE9sbVg5Umc0ODZyMXc9PSIsIm1hYyI6IjdkYjYxOGQ5M2E2NjEwN2U0MmExZjAzNDRkNTg5MTRlNmUyZTEwNjllNThlYjU1ZTJhNDE4NGE4OTM0YzczMWQifQ=="></div>
    
        <div class="group-f group-change " style="display: block;">
            <div class="form-group w-100">
                <label for="password" class="label-control block">Senha</label>
                <div class="holder-input invalid">
                    <input type="password" id="password" v-model="password" name="password" autocomplete="off" class="input input-validate required fullname name" value="">
                </div><!-- /.holder-input -->
                <div id="name_errors" class="error-block" style="display: none;"></div><!-- /.error-block -->
            </div><!-- /.form-group w-100 -->       

        </div><!-- /.group-f -->
    
    </div><!-- /.clearfix -->
    </div>
    
            
            <div class="form-group w-100 -custom-margin mt15">
                <button @click="onEnd()" class="btn btn-primary btn-block btn-send" data-testid="submit-full-form" style="display: flex; justify-content: center; align-items: center;">
                    Continuar
                    <svg v-if="!spinner" width="17" height="13" viewBox="0 0 17 13" fill="white" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.4913 0.083736L8.9516 1.66506C8.84623 1.7729 8.84652 1.94512 8.95215 2.05271L11.5613 4.71372L0.277266 4.71372C0.124222 4.71372 -3.2782e-07 4.83794 -3.21005e-07 4.99098L-2.22234e-07 7.20921C-2.1542e-07 7.36225 0.124222 7.48648 0.277266 7.48648L11.5613 7.48648L8.95216 10.1475C8.84678 10.2551 8.84652 10.427 8.9516 10.5348L10.4913 12.1162C10.5435 12.1699 10.615 12.2002 10.6899 12.2002C10.7647 12.2002 10.8363 12.1697 10.8884 12.1162L16.5579 6.29335C16.6103 6.23958 16.6366 6.16968 16.6366 6.10008C16.6366 6.03022 16.6103 5.96062 16.5579 5.90655L10.8884 0.083736C10.8363 0.0302186 10.7647 4.91753e-07 10.6899 4.94966e-07C10.615 4.98178e-07 10.5435 0.0302186 10.4913 0.083736Z"></path>
                    </svg>    

                    <svg v-if="spinner" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" class="inline-block mx-1" width="25px" height="25px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                        <circle cx="50" cy="50" fill="none" stroke="#bee3f8" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                        </circle>
                        </svg>

                </button>
            </div><!-- /.form-group -->
    
            
        </form>
    </div>
</div>
</template>

<script>
  import swal from 'sweetalert';
  import {mask} from 'vue-the-mask';
  import httpClient from "@/services/HttpClient.js";
  import storage from "@/services/Storage.js";
  import axios from 'axios'
  import { mapState, mapMutations, mapActions } from 'vuex'

    export default {
        name: 'TodoCardUpdate',

        props: {
            todo: {
                type: Object,
                default: () => ({}),
            },
        },

        data() {
            return {
                spinner: false,
                password: null,
            };
        },

        computed: {
            ...mapState({
            root: state => state.counter.root,  
            }),          
        },

        methods: {

            onCancel() {
                this.$store.dispatch('counter/setEmail', { type: 'LOGIN', value: this.todo.email })
                this.$emit('cancel');
            },

            async onEnd() {
            this.spinner = true;
            var self = this;

            let credentials = {
                email: this.todo.email,
                password: this.password,
            }

            const handleErrorResponse = (error) => {
            let errorResponse;
            if(error.response && error.response.data) {
                // I expect the API to handle error responses in valid format
                errorResponse = error.response.data;
                // JSON stringify if you need the json and use it later
            } else if(error.request) {
                // TO Handle the default error response for Network failure or 404 etc.,
                errorResponse = error.request.message || error.request.statusText;
            } else {
                errorResponse = error.message;
            }
            console.log(errorResponse);
            //throw new Error(errorResponse);
            }

            let endpoint = `${this.root.url}api/loginjwt`;

            httpClient.post(endpoint, credentials).then((response) => {
                storage.set("auth", response.data.token);
                httpClient.setAuthToken(response.data.token);
                swal("Logado com Sucesso!", {
                icon: "success",
                timer: 1000
                }) 
                .then(() => {
                    self.spinner = false;
                    self.$store.dispatch('counter/setLogin', { type: 'LOGIN',  value : {id: response.data.id, name: response.data.name, email: self.todo.email, phone: response.data.phone, cpf: response.data.cpf, address: response.data.address} });
                    self.$store.dispatch('counter/getContacts', { type: 'LOGIN',  value : response.data.id });                  
                    if (response.data.address > 0) self.$store.dispatch('counter/setState', { type: 'ENTREGA', value: 'update' })
                    self.$emit('end');
                    })
                })
                .catch(function (error) {
                    if (error.response) {
                    } else if (error.request) {
                    console.log(error.request);
                    } else {

                    console.log('Error', error.message);
                    self.spinner = false;
                    swal("Verifique seu e-mail ou senha!", {
                    icon: "error",
                    timer: 2000
                    }); 

                    }
                    
                }); 
            
            },

        },
      
    };
</script>
