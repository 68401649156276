<template>
    <div class="payment payment-trigger pix box-10" :class="selecionado ? 'selected' : ''"  @click.prevent="$emit('updatePay')" data-affiliation="mercadopago">
        <label for="payment-pix" class="payment-header block clearfix">
            <div class="holder-icheck pull-left">
                <div class="iradio_minimal"  :class="selecionado ? 'checked' : ''" style="position: relative;"><input type="radio" id="payment-pix" name="payment-type" class="select-payment" data-name="payment-type" value="43" data-alias="pix" data-url="cart/payment" style="position: absolute; visibility: hidden;"><ins class="iCheck-helper" style="position: absolute; top: 0%; left: 0%; display: block; width: 100%; height: 100%; margin: 0px; padding: 0px; background: rgb(255, 255, 255); border: 0px; opacity: 0;"></ins></div>
                <label for="payment-pix" class="holder-label medium f14 black-80">
                    <i class="icon icon-pix mr5"></i>
                    <span class="name">Pix</span>
                </label>
            </div>

                    </label>

        <div class="payment-content">
            <div class="pix-desc mt5 mb20 medium f13 black-80">
                <p>
                    A confirmação de pagamento é realizada em poucos minutos.
                    Utilize o aplicativo do seu banco para pagar.
                </p>
            </div><!-- /.mt10 -->

            <div v-if="pix">
                <img :src="pix.qr_codes[0].links[0].href" class="img-fluid"/>
                <input className="form-control" type="text" id="copiarpix" :value="pix.qr_codes[0].text" readOnly/>
                <Button
                        className="btn btn-primary btn-block"
                        @click="onCopiarPix()"
                    >&nbsp; Copiar Código
                </Button>
            </div>

            <div v-if="!pix" class="pix-price f16 bold mb10">
                Valor no Pix: <span class="cart-total js-cart-total">R$ {{total.replace('.',',')}}</span>
            </div>

            <div class="js-orderbumps">
        </div>

            
            <div class="mt20">
                <button v-if="!pix" @click="onPayPix()" class="btn btn-primary btn-block btn-send btn-finalize with-icon" style="display: flex; justify-content: center; align-items: center;" data-testid="buy-now-pix">
                    Comprar agora 
                 
                    <svg v-if="spinner" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" class="inline-block mx-1" width="25px" height="25px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                        <circle cx="50" cy="50" fill="none" stroke="#bee3f8" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                        </circle>
                        </svg>

                </button>
            </div><!-- /.form-group -->

        </div><!-- /.payment-content -->
        <div class="overlay-spinner overlay-spinner-payment">
            <div class="spinner spinner-grey"></div><!-- /.spinner spinner-grey -->
        </div><!-- /.spinner-box -->
        </div><!-- /.payment -->

        <PixFinal v-if="pix"/>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import PixFinal from '@/components/Pagamento/PixEnd.vue'
import swal from 'sweetalert'

    export default {
        name: 'TodoCardEnd',
        
        components: {
            PixFinal,
        },

        props: {
            selecionado: false,                           
            updatePay: Function,
        },

        data() {
            return {
                spinner: false,
            };
        },

        computed: {
            ...mapState({
            login: state => state.counter.login,    
            total: state => state.counter.root.total,
            emailtoken: state => state.counter.pagseguro.email,  
            pix: state => state.counter.pagseguro.pix      
            }),

        },

        methods: {

            onCopiarPix() {
            
            var copyText = document.getElementById("copiarpix");
            copyText.select();
            copyText.setSelectionRange(0, 99999); /* For mobile devices */
            document.execCommand("copy");

            swal("Copiado com sucesso!", {
                icon: "success",
                timer: 1000
                });

            },

            onPayPix() {

                this.spinner = true;

                var data = {
                'amount': this.total,
                'cel'   : this.login.phone,
                'cpf'   : this.login.cpf.replace(/[^a-zA-Z0-9]/g, ''),
                'email' : this.login.email,
                'emailtoken' : this.emailtoken,
                'first_name' : this.login.name,
                'hash'  : 0,
                'last_name' : "",
                'payment_method_id' : "pix",
                'product' : "Pedido",
                'product_id' : Math.floor(Math.random() * 100000) + '' + Date.now(),
                'user_id' : null,
                };

            this.$store.dispatch('counter/payPix', { type: 'PAGAMENTO', data });
            this.$emit('enviarMensagem');
            },

            onUpdate() {
                this.$emit('update');
            },
        },
    };
</script>
