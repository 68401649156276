<template>
    <div class="container-fluid clearfix text-center checkout-finalization" style="position: fixed; top: 0; left: 0;  background-color: #f4f6f8;  z-index: 10000;  width: 100%;  height: 100vh;  padding-top: 80px;">
        
        <h1 class="t1 ctx-title bold mb15 mt30">
            Seu pedido foi realizado.
    </h1>

<p class="t2 ctx-c-description f16">
Daqui a pouco você receberá um e-mail no endereço
<span class="bold">{{login.email}}</span>
com todos os detalhes de sua compra.
</p>

<div class="holder-buttons mt20 mb40">


<div class="clearfix">
<a :href="root.url" class="btn btn-grey mr10 ml10">
Voltar para o site
</a>
</div>

</div><!-- /.holder-buttons -->


</div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import swal from 'sweetalert'
    export default {
        name: 'TodoCardEndCard',

        props: {
            todo: {
                type: Object,
                default: () => ({}),
            },
        },

        computed: {
            ...mapState({
            login: state => state.counter.login,
            root: state => state.counter.root,            
            }),

        },

        data() {
            return {
                email: this.todo.email
            };
        },

        methods: {

            onUpdate() {
                this.$emit('update');
            },
        },
    };
</script>
