<template>
  <div class="container">
    <Search />
  </div>
</template>

<script>
import Search from '@/components/Search';

export default {
  name: 'Pesquisar',
  components: {
    Search,
  },
}
</script>
