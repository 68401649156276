import axios from "axios";
import storage from "./Storage.js";

let token = storage.get("token");

const httpFactory = axios.create({
  baseURL: "https://mybrain.com.br/api",
  withCredentials: false,
});

const httpClient = {
  get(endpoint) {
    return httpFactory.get(endpoint);
  },
  post(endpoint, data) {
    return httpFactory.post(endpoint, data);
  },
  put(endpoint, data) {
    return httpFactory.put(endpoint, data);
  },
  delete(endpoint) {
    return httpFactory.delete(endpoint);
  },
  setAuthToken(token) {
    httpFactory.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  },
  setInterceptor() {
    httpFactory.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status == 401) {
          localStorage.removeItem('auth');
          //location.href = "/auth/login";
          return;

          /*
          swal("Verifique seu e-mail ou senha!", {
            icon: "error",
            timer: 2000
            }) 
            .then(() => {
              setTimeout(function() {
                localStorage.removeItem('auth');
                location.href = "/auth/login";
                return;
              }, 1000);
            })    
        */

          }

        return Promise.reject(error);
      }
    )
  },
};

export default httpClient;
