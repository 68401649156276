import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import SearchView from "../views/SearchView.vue";
import AboutView from "../views/AboutView.vue";
import FreteView from "../views/FreteView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/biblia",
    name: "biblia",
    component: AboutView,
  },
  {
    path: "/pesquisar",
    name: "pesquisar",
    component: SearchView,
  },
  {
    path: "/frete",
    name: "frete",
    component: HomeView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
