<template>
    <div class="payment payment-trigger credit-card box-0" :class="selecionado ? 'selected' : ''" @click.prevent="$emit('updatePay')">
        <label for="payment-credit-card" class="payment-header block clearfix">
            <div class="holder-icheck pull-left">
                <div class="iradio_minimal" :class="selecionado ? 'checked' : ''" style="position: relative;"><input type="radio" id="payment-credit-card" name="payment-type" data-name="payment-type" value="" data-alias="credit_card" class="select-payment" data-url="cart/payment" style="position: absolute; visibility: hidden;"><ins class="iCheck-helper" style="position: absolute; top: 0%; left: 0%; display: block; width: 100%; height: 100%; margin: 0px; padding: 0px; background: rgb(255, 255, 255); border: 0px; opacity: 0;"></ins></div>
                <label for="payment-credit-card" class="holder-label medium f14 black-80 label-credit-card">
                    <span class="name">Cartão de crédito</span>
                    <div class="card-brands-payment cards mt10">
                                                                                    <img src="https://github.bubbstore.com/svg/card-amex.svg" height="20" alt="American Express" class="card-icon">
                                                                                                                <img src="https://github.bubbstore.com/svg/card-visa.svg" height="20" alt="Visa" class="card-icon">
                                                                                                                <img src="https://github.bubbstore.com/svg/card-diners.svg" height="20" alt="Diners" class="card-icon">
                                                                                                                <img src="https://github.bubbstore.com/svg/card-mastercard.svg" height="20" alt="Mastercard" class="card-icon">
                                                                                                                <img src="https://github.bubbstore.com/svg/card-discover.svg" height="20" alt="Discover" class="card-icon">
                                                                                                                <img src="https://github.bubbstore.com/svg/card-aura.svg" height="20" alt="Aura" class="card-icon">
                                                                                                                <img src="https://github.bubbstore.com/svg/card-hipercard.svg" height="20" alt="Hipercard" class="card-icon">
                                                                        </div>
                </label>
            </div>
                    </label>

        <div class="payment-content payment-content-creditcard clearfix">
            <div class="inner-form">
                <div id="creditcard-single" class="creditcard-content">
                    <div id="creditcard-container-1" class="creditcard-container clearfix" data-index="0">
                        <span data-hj-masked="" data-hj-suppress="" data-clarity-mask="True" data-mf-replace="**REMOVED**">
                            <div class="card-wrapper"></div><!-- /.card-wrapper -->
                        </span>
                        <div class="form-group w-100">
        <label for="input-card-number" class="label-control block">Número do cartão</label>
        <input type="tel" data-name="card[0][number]" v-model="card.number" v-mask="'#### #### #### ####'" data-checkout="cardNumber" id="input-card-number-0" class="bold l-space1 input-validate required input input-card-number bincreditcard" data-iugu="number" data-url="api/v1/installments" placeholder="1234 1234 1234 1234" data-testid="input-cc-number">
        <div id="input-card-number-0_errors" class="error-block"></div><!-- /.error-block -->
        </div><!-- /.form-group w-100 -->

        <div class="form-group w-50 keep-size -padding-right">
        <label for="input-card-validaty" class="label-control block">Validade <span class="f11 black-60">(mês/ano)</span></label>
        <div class="relative">
        <input type="tel" data-iugu="expiration" data-name="card[0][expiry]" v-model="card.date" v-mask="'##/####'" id="input-card-validaty-0" class="input-validate required input input-card-expiry mindate" data-testid="input-cc-expiration" placeholder="MM/AAAA" maxlength="7">
        <div class="holder-help">
            <i class="fa fa-question-circle"></i>
            <div class="holder-tooltip">
                <div class="tooltip">
                    <p class="f11">
                        Validade do seu cartão. <br>
                        Exemplo: 10/19 <br><br>
                        Onde <strong>10</strong> é o mês e <strong>19</strong> é o ano
                    </p><!-- /.f11 -->

                </div>
            </div>
        </div>
        </div><!-- /.relative -->
        <div id="input-card-validaty-0_errors" class="error-block"></div><!-- /.error-block -->
        </div><!-- /.form-group -->

        <div class="form-group w-50 keep-size -padding-left">
        <label for="input-security-number" class="label-control -nowrap block">
        Cód. de segurança
        <span class="relative holder-tooltip ml2">
            <i class="icon icon-question-tip"></i>
            <span class="tooltip2">
        <div class="tt-content-holder" style="width: 101px">
        <div class="tt-content bold text-center">
                            <div class="tt-line">
                    3 dígitos no verso do cartão. Amex: 4 dígitos na frente.
                </div>
                    </div>
        </div>
        </span>        </span>
        </label>
        <div class="relative">
        <input type="tel" data-name="card[0][cvv]" data-checkout="securityCode" v-model="card.code" id="input-security-number-0" class="input-validate required input input-card-security-number maxlength" maxlength="4" data-testid="input-cc-sec-code">
        <div class="holder-help">
            <i class="fa fa-question-circle"></i>
            <div class="holder-tooltip">
                <div class="tooltip">
                    <div class="icon security-number"></div>
                </div>
            </div>
        </div>
        </div><!-- /.relative -->
        <div id="input-security-number-0_errors" class="error-block"></div><!-- /.error-block -->
        </div><!-- /.form-group -->

        <div class="form-group w-100">
        <label id="input-card-name" for="input-card-name" class="label-control block">Nome e sobrenome do titular</label>
        <input type="text" data-iugu="full_name" data-name="card[0][holder_name]" v-model="card.name" data-checkout="cardholderName" id="input-card-name-0" class="input-validate required input input-card-name" placeholder="ex.: Maria de Almeida Cruz" data-testid="input-cc-card-name">
        <div id="input-card-name-0_errors" class="error-block"></div><!-- /.error-block -->
        </div><!-- /.form-group -->

        <div class="form-group keep-size w-100">
        <label for="input-card-name" class="label-control block">CPF do titular</label>
        <input type="tel" id="input-card-document-0" v-model="card.cpf" v-mask="'###.###.###-##'" class="input-validate required input input-card-document cpf" data-name="card[0][holder_document]" value="" data-checkout="docNumber" data-testid="input-cc-document" placeholder="000.000.000-00" required="" maxlength="14">
        <div id="input-card-document-0_errors" class="error-block"></div><!-- /.error-block -->
        </div><!-- /.form-group -->

        <div class="form-group w-62 hide moip-element pagseguro-element keep-size">
        <label for="input-card-name" class="label-control block">Nascimento do titular</label>
        <input type="tel" id="input-card-birthdate-0" class="input-validate required input input-card-birthdate date" data-name="card[0][holder_birthdate]" data-checkout="holderBirthdate" placeholder="00/00/0000" value="" maxlength="10">
        <div id="input-card-birthdate-0_errors" class="error-block"></div><!-- /.error-block -->
        </div><!-- /.form-group -->

        <input type="hidden" data-name="card[0][year]" data-checkout="cardExpirationYear">

        <input type="hidden" data-name="card[0][month]" data-checkout="cardExpirationMonth">

        <input type="hidden" data-name="card[0][gateway]" class="gateway">
        <input type="hidden" data-name="card[0][affiliationId]" class="affiliation-id">
        <input type="hidden" id="brand" data-name="card[0][brand]">
        <input type="hidden" class="fake-card-number" value="411111">

        <div class="form-group form-group-installments w-100">
        <label for="input-parcels-number" class="label-control block">
        Nº de Parcelas
        </label>

        <div class="holder-installments">
        <div class="holder-selectskin">
            <div class="select-skin">
                <select id="installments" data-testid="input-cc-installments" v-model="parcelaatual" :value="parcela" data-name="card[0][installments]" class="selectskin installments" style="width: 100%;">                    
                    <option value="0">Selecionar</option>
                    <option v-for="(parcela, index) in parcelas" v-bind:key="index" :value="parcela">{{`${index+1} x R$ ${parcela}`}}</option>
                </select>
                <div class="select-skin-mask"><div class="select-skin-text-clip"><div class="select-skin-text">---</div></div></div></div>
        </div><!-- /.holder-select -->
        <div class="error-block"></div><!-- /.error-block -->
        <span class="black-60 f11 block mt5 regular mb5 installments-helper js-installments-helper">Preencha o cartão para selecionar as parcelas</span>
        <div class="mt5 installments-rate-warning f11 black-60 hide">* há um acréscimo de <span class="tax"></span>%<span class="tax-monthly"> ao mês</span>.</div>
        </div>

        </div><!-- /.form-group -->

        <input type="hidden" data-name="card[0][customerphone]" value="(87) 68768-7687">
        <input type="hidden" id="docType" data-name="card[0][docType]" data-checkout="docType" value="CPF">						<input type="hidden" id="amount" class="input-amount" data-name="card[0][amount]" value="197,90">
                    </div><!-- /.creditcard-container -->
                </div><!-- /.creditcard-content -->

            </div><!-- /.inner-form -->

            <div v-if="cardresult">
                PAGAMENTO EFETUADO COM SUCESSO!!!
                {{ cardresult.id }}
            </div>

            <div class="js-orderbumps">
        </div>

            
            <div class="mt10 holder-buy-button">
                <button v-if="!cardresult" @click="onPayCard()" data-testid="buy-now-cc" class="btn btn-primary btn-block btn-send btn-finalize with-icon" style="display: flex; justify-content: center; align-items: center;">
                    Comprar agora

                    <svg v-if="spinner" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" class="inline-block mx-1" width="25px" height="25px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                        <circle cx="50" cy="50" fill="none" stroke="#bee3f8" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                        </circle>
                        </svg>

                </button>
            </div><!-- /.form-group -->

        </div><!-- /.payment-content -->

        <div class="overlay-spinner overlay-spinner-payment">
            <div class="spinner spinner-grey"></div><!-- /.spinner spinner-grey -->
        </div><!-- /.spinner-box -->
        </div><!-- /.payment -->

        <CardFinal v-if="cardresult"/>
</template>

<script>
  import { mapState, mapMutations, mapActions } from 'vuex'
  import CardFinal from '@/components/Pagamento/CardEnd.vue'
  import swal from 'sweetalert'
  import Field from '../field'
  import TheMask from 'vue-the-mask'
  import {mask} from 'vue-the-mask'
  import axios from 'axios'

    export default {
        name: 'TodoCard',

        components: {
            CardFinal,
        },

        props: {
            selecionado: false,                         
            updatePay: Function,
        },

        computed: {
            
            ...mapState({
            login: state => state.counter.login,
            total: state => state.counter.root.total,
            token: state => state.counter.pagseguro.token,
            emailtoken: state => state.counter.pagseguro.email,
            parcelas: state => state.counter.pagseguro.parcelas,
            publicKeys: state => state.counter.pagseguro.publicKeys,
            cardresult: state => state.counter.pagseguro.card 
            }),

            code () {
            return `<the-mask mask="${this.mask}" value="${this.value}" type="${this.type}" masked="${this.masked}" placeholder="${this.placeholder}"></the-mask>`
            }
        },

        created () {
            const script = document.createElement('script')
            script.src = 'https://assets.pagseguro.com.br/checkout-sdk-js/rc/dist/browser/pagseguro.min.js'
            script.addEventListener('load', () => {
            //window.Mercadopago.setPublishableKey(MP_PUBLIC_KEY)
            //window.Mercadopago.getIdentificationTypes()
            //console.log (ver);
            })
            document.body.appendChild(script)
        },

        data() {
            return {    
                spinner: false,
                parcelaatual:0,   
                masked: false,
                iban: 'BR0500000000011870000713973C1',
                vehicle: 'KNDJB723025140702',
                hexTokens: {
                    F: {
                    pattern: /[0-9a-fA-F]/,
                    transform: v => v.toLocaleUpperCase()
                    }
                },
                card: {
                    number: '',
                    name: '',
                    cpf: '',
                    date: '',
                    code: '',
                },               
                type: 'text',
                placeholder: 'test your mask here',
                mask: '#XSAa',
                value: '12TgB',
                directive: `<input type="tel" v-mask="'##/##/####'" />`,                
                description: null,                      
            };
        },

        methods: {

            onPayCard() {

            let cpflimpo = this.card.cpf.replace(/[^a-zA-Z0-9]/g, '');
            if (this.validarCPF(cpflimpo)) {

            if (this.parcelaatual) {

            this.spinner = true;

            var cardExpirationx = this.card.date.split('/');

            const card = window.PagSeguro.encryptCard({
                    publicKey: this.publicKeys,
                    holder: this.card.name,
                    number: this.card.number.replace(/[^a-zA-Z0-9]/g, ''), // Número do cartão de crédito / 411111111111111
                    securityCode: this.card.code, // CVV do cartão / 013
                    expMonth: cardExpirationx[0], // Mês da expiração do cartão / 12
                    expYear: cardExpirationx[1], // Ano da expiração do cartão, é necessário os 4 dígitos. / 2026
                });

            const encrypted = card.encryptedCard;
            var e = document.getElementById("installments");
            var strinstallments = e.options[e.selectedIndex].value;
            var qtdinstallments = e.options[e.selectedIndex].index+1;

            var cardtoken = {
                cardName: this.card.name,
                cardNparcelas: qtdinstallments,
                encrypted: encrypted,
                cardNumber: this.card.number.replace(/[^a-zA-Z0-9]/g, ''),
                cardCvv: this.card.code
              };

            var data = {
            'cardtoken' : cardtoken,
            'payment_method_id' : 'card',
            'cardholderName': this.card.name,
            'installmentValue': strinstallments.replace(',','.'),
            'installmentQuantity': qtdinstallments,
            'amount': this.total,
            'issuer': null,
            'cel'   : this.login.phone,
            'cpf'   : this.card.cpf.replace(/[^a-zA-Z0-9]/g, ''),
            'email' : this.login.email,
            'emailtoken' : this.emailtoken,
            'first_name' : this.card.name,
            'hash'  : 0,
            'last_name' : "",
            'product' : "Pedido",
            'product_id' : Math.floor(Math.random() * 100000) + '' + Date.now(),
            'user_id' : null
            };

            this.$store.dispatch('counter/payCard', { type: 'PAGAMENTO', data });
            this.$emit('enviarMensagem');
            } else {

                swal("Informe o número de parcelas!", {
                    icon: "error",
                    timer: 2000
                    }); 
            }  
            
            } else {
                    this.spinner = false; 
                    swal("Cpf inválido!", {
                    icon: "error",
                    timer: 2000
                    });                         
                }

            },

            onUpdate() {
                this.$emit('update');
            },

            validarCPF(inputCPF){
                var soma = 0;
                var resto;
                //var inputCPF = document.getElementById('cpf').value;

                if(inputCPF == '00000000000') return false;
                for(let i=1; i<=9; i++) soma = soma + parseInt(inputCPF.substring(i-1, i)) * (11 - i);
                resto = (soma * 10) % 11;

                if((resto == 10) || (resto == 11)) resto = 0;
                if(resto != parseInt(inputCPF.substring(9, 10))) return false;

                soma = 0;
                for(let i = 1; i <= 10; i++) soma = soma + parseInt(inputCPF.substring(i-1, i))*(12-i);
                resto = (soma * 10) % 11;

                if((resto == 10) || (resto == 11)) resto = 0;
                if(resto != parseInt(inputCPF.substring(10, 11))) return false;
                return true;
            }            

        },
   
        directives: {mask}   
    };
</script>
