export default {
  INCREMENT(state, value) {
    state.counter += value;
  },

  DECREMENT(state, value) {
    state.counter -= value;
  },

  SETVersoes(state, value) {
    state.versoes = value;
  },

  SETArea(state, value) {
    state.area = value;
  },

  UPDATETotal(state) {
    state.root.total = (parseFloat(state.root.subtotal) + parseFloat(state.frete.price) - parseFloat(state.root.discount)).toFixed(2);
  },

  SETContact(state, value) {
    state.contact = value;
  },

  SETPagSeguroToken(state, value) {
    state.pagseguro.token = value;
  },

  SETStatus(state, value) {
    state.pagseguro.status = value;
  },

  SETContactSelected(state, value) {

    state.contacts.forEach(function(nome, i) {
        nome.selected = false;
    }), 

    state.contacts.forEach(function(nome, i) {
      if (nome.id == value.id) {
        nome.selected = true;
        return;
      } 
    })

  },
  
  SETFrete(state, value) {
    state.frete = value;
  },

  SETParcelas(state, value) {
    state.pagseguro.parcelas = value;
  },

  SETPublicKeys(state, value) {
    state.pagseguro.publicKeys = value;
  },

  SETContactId(state, value) {
    state.contactId = value;
  },

  SETLogin(state, value) {
    state.login = value;
  },

  SETRoot(state, value) {
    state.root = value;
  },

  SETisEdit(state, value) {
    state.isEdit = value;
  },

  SETHideAddress(state, value) {
    state.hideaddress = value;
  },

  SETEmail(state, value) {
    state.login.email = value;
  },

  SETNewUser(state, value) {
    state.newUser = value;
  },

  SETName(state, value) {
    state.login.name = value;
  },

  SETCpf(state, value) {
    state.login.cpf = value;
  },

  SETPhone(state, value) {
    state.login.phone = value;
  },

  SETPersonalData(state, value) {
    state.login = value;
  },

  SETStateLogin(state, value) {
    state.login.state = value;
  },

  SETStateEntrega(state, value) {
    state.entrega.state = value;
  },

  SETStatePagamento(state, value) {
    state.pagamento.state = value;
  },

  SETContacts(state, value) {
    state.contacts = value;
  },

  SETContactAtual(state, value) {
    state.contact = value;
  },

  SETCard(state, value) {
    state.pagseguro.card = value;
  },

  SETPix(state, value) {
    state.pagseguro.pix = value;
  },

  SETBullet(state, value) {
    state.pagseguro.bullet = value;
  },

  SETTestamentos(state, value) {
    state.testamentos = value;
  },

  SETLivros(state, value) {
    state.livros = value;
  },

  SETVersiculos(state, value) {
    state.versiculos = value;
  },

  SETCapitulos(state, value) {
    state.capitulos = value;
  },

  SETSearch(state, value) {
    state.search = value;
  },

}
