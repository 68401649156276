<template>
    <div class="max-w-7xl mx-auto my-2">

        <TodoCardShow
            v-if="isShowing"
            :todo="todo"
            @update="changeStateToUpdate"
            @new="changeStateToNew"
        />

        <TodoCardUpdate
            v-if="isUpdating"
            :todo="todo"
            @end="changeStateToEnd"
            @cancel="changeStateToShow"
        />

        <TodoCardNew
            v-if="isNew"
            :todo="todo"
            @end="changeStateToEnd"
            @cancel="changeStateToShow"
            />

        <TodoCardEnd
            v-if="isEnding"
            :todo="todo"
            @update="changeStateToUpdate"
        />

    </div>
</template>

<script>
    import TodoCardShow from '@/components/Login/TodoCardShowLogin.vue';
    import TodoCardUpdate from '@/components/Login/TodoCardUpdateLogin.vue';
    import TodoCardNew from '@/components/Login/TodoCardNewLogin.vue';
    import TodoCardEnd from '@/components/Login/TodoCardEndLogin.vue';
    import { mapState, mapMutations, mapActions } from 'vuex'

    export default {
        name: 'TodoCard',

        components: {
            TodoCardShow,
            TodoCardUpdate,
            TodoCardNew,
            TodoCardEnd,
        },

        props: {
            todo: {
                type: Object,
                default: () => ({}),
            },
        },

        data() {
            return {
                
            };
        },

        computed: {        

            isShowing() {
                return this.todo.state === 'show';
            },

            isUpdating() {
                return this.todo.state === 'update';
            },

            isNew() {
                return this.todo.state === 'new';
            },

            isEnding() {
                return this.todo.state === 'end';
            },

        },

        methods: {
            changeStateToShow() {
                this.$store.dispatch('counter/setState', { type: 'LOGIN', value: 'show' })
                //this.todo.state = 'show';
            },

            changeStateToUpdate() {
                this.$store.dispatch('counter/setState', { type: 'LOGIN', value: 'update' })
                //this.todo.state = 'update';
            },

            changeStateToNew() {
                this.$store.dispatch('counter/setState', { type: 'LOGIN', value: 'new' })
                //this.todo.state = 'update';
            },

            changeStateToEnd() {
                this.$store.dispatch('counter/setArea', { type: 'ENTREGA', value: 1 });                
                this.$store.dispatch('counter/setState', { type: 'LOGIN', value: 'end' })
                //this.todo.state = 'end';
            },

        },
    };
</script>
