<template>
    <div class="col-checkout col-2">
        <div class="box-checkout box-payment active" data-url="checkout/payment" data-target=".box-payment">

        <div class="box-title">
        <div class="holder-number">3</div><!-- /.holder-number -->
        <div class="title f-h2 ctx-title bold">Pagamento</div><!-- /.f-h2 -->
        <div class="desc">
     
        Escolha uma forma de pagamento
            </div>
    </div><!-- /.box-title -->

    <div class="box-content">
    <form action="checkout/pay" id="form-checkout" class="form-checkout form-horizontal clearfix text-left" method="POST">
        
        <div class="payments mt15" v-for="(payment, index) in payments" v-bind:key="payment.id">
            <div class="clearfix mercadopago-flag mercadopago-element">
                <div class="inline-block mb10 mt20">
                    <span class="text mr5 f12 black-80">Processado por</span>
                    <div class="icon icon-mercadopago mb5"></div><!-- /.icon icon-mercadopago -->
                </div><!-- /.mercadopago-flag mercadopago-element icon icon-mercadopago -->
            </div><!-- /.text-center clearfix -->

            <component v-bind:is="payment.component" :selecionado="payment.selected" @enviarMensagem="enviarMensagem()" @updatePay="selectPayment(payment,index)"></component>
  

        </div><!-- /.payments -->
    </form>
    </div><!-- /.box-content -->

<div class="overlay-spinner overlay-spinner-box">
<div class="spinner spinner-grey"></div><!-- /.spinner spinner-grey -->
</div><!-- /.spinner-box -->
</div><!-- /.box-checkout -->
    </div>
</template>

<script>
  import { mapState, mapMutations, mapActions } from 'vuex'
  import swal from 'sweetalert'
  import Field from '../field'
  import TheMask from 'vue-the-mask'
  import {mask} from 'vue-the-mask'
  import axios from 'axios'
  import Pix from '@/components/Pagamento/Pix.vue'
  import Bullet from '@/components/Pagamento/Bullet.vue'
  import Card from '@/components/Pagamento/Card.vue'
  import httpClient from "@/services/HttpClient.js"

    export default {
        name: 'TodoCardUpdate',

        components: {
            Card,
            Pix,
            Bullet
        },

        props: {
            todo: {
                type: Object,
                default: () => ({}),
            },
        },


        computed: {
            
            ...mapState({
            root: state => state.counter.root,
            total: state => state.counter.root.total,
            token: state => state.counter.pagseguro.token,      
            }),

            code () {
            return `<the-mask mask="${this.mask}" value="${this.value}" type="${this.type}" masked="${this.masked}" placeholder="${this.placeholder}"></the-mask>`
            }
        },

        data() {
            return {
                payments: [{id: 0, name: 'Card', component: Card, index: 0},{id: 0, name: 'Pix', component: Pix, index: 1},{id: 2, name: 'Bullet', component: Bullet, index: 2}],
                payment: {
                    id: '',
                    name: '',
                    selected: false
                },
                parcelas: [
                    'janeiro','fev','mar'
                ],
                masked: false,
                iban: 'BR0500000000011870000713973C1',
                vehicle: 'KNDJB723025140702',
                hexTokens: {
                    F: {
                    pattern: /[0-9a-fA-F]/,
                    transform: v => v.toLocaleUpperCase()
                    }
                },              
                type: 'text',
                placeholder: 'test your mask here',
                mask: '#XSAa',
                value: '12TgB',
                directive: `<input type="tel" v-mask="'##/##/####'" />`,                
                email: this.todo.email,
                name: this.todo.name,
                cpf: this.todo.cpf,
                phone: this.todo.phone,
                description: null,
            };
        },

        mounted() {
            //this.$refs.paginit.focus();
            //this.$refs.label.focus();
            //this.email = this.todo.email;
            //console.log(this.todo.email);
        },

        created () {
            this.onPublicKeys();
            this.getParcelas (this.total);
            this.scrollToTop();
        },

        methods: {

            scrollToTop() {
                window.scrollTo(0,0);
            },

            enviarMensagem() {
            const payload = {
                port: 8001,
                phone: '5514997241400',
                msg: 'Você teve uma nova venda!!!',
            };

            httpClient
            .post(`https://www.mybrain.com.br/api/sendmessagenew`, payload).then((r) => {

            }).catch((error) => {

            });            

            },

            selectPayment(payment, index) {
            this.payment = payment;
            this.payment.selected = true;
            Object.keys(this.payments).forEach(key => this.payments[key].selected = false);
            this.payments[index].selected = true;
            },

            onPublicKeys() {
            this.$store.dispatch('counter/getPublicKeys');
            },

            getParcelas (total) {

            var data = {
                'total': total,
                'token' : this.token
            };

            var self = this;
            return axios
            .post(`${this.root.url}api/pay/card/installments`, {
                "body" : data
            })
            .then(function(response) {
                self.$store.dispatch('counter/setParcelas', { type: 'ENTREGA', value: response.data });
            })
            .catch(function(error) {
                console.log(error);
            });

            }, 

        },

        directives: {mask}        
    };
</script>
