<template>
    <div class="col-checkout col-2">
        <div class="box-checkout box-payment disabled" data-url="checkout/payment" data-target=".box-payment">

            <div class="box-title">
            <div class="holder-number">3</div><!-- /.holder-number -->
            <div class="title f-h2 ctx-title bold">Pagamento</div><!-- /.f-h2 -->
            <div class="desc">Preencha suas informações de entrega para continuar</div>
            </div><!-- /.box-title -->

    
            <div class="overlay-spinner overlay-spinner-box">
            <div class="spinner spinner-grey"></div><!-- /.spinner spinner-grey -->
            </div><!-- /.spinner-box -->
        
        </div><!-- /.box-checkout -->

    </div><!-- /.col-checkout -->
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import Field from '../field'
import TheMask from 'vue-the-mask'
import {mask} from 'vue-the-mask'
import axios from 'axios'

    export default {
        name: 'TodoCardShow',

        props: {
            todo: {
                type: Object,
                default: () => ({}),
            },
        },

        computed: {
            ...mapState({
            root: state => state.counter.root,
            area: state => state.counter.area,
            contactAtual: state => state.counter.contactAtual,
            isEdit: state => state.counter.isEdit,
            hideaddress: state => state.counter.hideaddress,
            login: state => state.counter.login,
            entrega: state => state.counter.entrega,
            total: state => state.counter.total      
            }),

            code () {
            return `<the-mask mask="${this.mask}" value="${this.value}" type="${this.type}" masked="${this.masked}" placeholder="${this.placeholder}"></the-mask>`
            }            
        },

        data() {
            return {
                masked: false,
                iban: 'BR0500000000011870000713973C1',
                vehicle: 'KNDJB723025140702',
                hexTokens: {
                    F: {
                    pattern: /[0-9a-fA-F]/,
                    transform: v => v.toLocaleUpperCase()
                    }
                },
                contacts: [],
                contact: {
                    id: '',
                    street: '',
                    number: '',
                    complement: '',
                    district: '',
                    postalcode: '',
                    city: '',
                    state: '',
                    country: 'BRA'
                },                
                type: 'text',
                placeholder: 'test your mask here',
                mask: '#XSAa',
                value: '12TgB',
                directive: `<input type="tel" v-mask="'##/##/####'" />`,                
                email: this.todo.email,
                name: this.todo.name,
                cpf: this.todo.cpf,
                cep: this.todo.cep,
                phone: this.todo.phone,
                description: null
            };
        },

        methods: {
            onUpdate() {
                this.todo.email = this.email;
                this.$emit('update');
            },

            mostraDados() {

            var self=this;
            const cep = this.contact.postalcode;
            fetch (`https://viacep.com.br/ws/${cep}/json/`)
            .then (respostaDoServer => {
                return respostaDoServer.json();
            })
            .then (dadosDoCep => {

            self.contact.street = dadosDoCep.logradouro;
            self.contact.district = dadosDoCep.bairro;
            self.contact.number = '';
            self.contact.state = dadosDoCep.uf;
            self.contact.city = dadosDoCep.localidade;
            self.contact.complement = dadosDoCep.complemento;

            //self.hideaddress = false;
            self.$store.dispatch('counter/setHideAddress', { type: 'ENTREGA', value: false });
            });

            },	

            saveContact(contact) {

            contact.id = null;
            contact.user_id = 1;//this.clientId;

            var self=this;
            axios
            .post(`${this.root.url}api/address`, contact)
            .then(function (response) {
                    //self.atualizaLista();
                    self.contacts.push(response.data);
                    self.setContact(response.data);
                    contact.street = '';
                    contact.postalcode = '';
                    contact.district = '';
                    contact.number = '';
                    contact.state = '';
                    contact.city = '';
                    contact.complement = '';
                    //console.log(self.contacts[0].id);
                    //self.setContact(self.contacts[0]);
                    self.hideaddress = true;
                    self.$store.dispatch('counter/setState', { type: 'ENTREGA', value: 'update' });
            })
            .catch(function (err) {
                //console.log(contact);
                console.log(err);
            });
            
            },


            updateContact(contact) {
        
                contact.id = this.contactAtual;
                var self=this;
                axios
                .put(`${this.root.url}api/address/`+ this.contactAtual, contact)
                .then(function (response) {
                        //self.atualizaLista();
                        //self.isEdit = false;
                        contact.street = '';
                        contact.postalcode = '';
                        contact.district = '';
                        contact.number = '';
                        contact.state = '';
                        contact.city = '';
                        contact.complement = '';
                        self.$store.dispatch('counter/setState', { type: 'ENTREGA', value: 'update' });
                })
                .catch(function (err) {
                    console.log(err);
                });
            
            },

            setContact(contact) {

            contact.address = contact.id;
            var self=this;
            axios
            .put(`${this.root.url}api/user/1`, contact)
            .then(function (response) {
                    self.setMenu1Active();
                    var este = self.contacts.find(x => x.id === response.data.address);
                    self.pref = este;
                    
                    //self.atualizaLista();
                
                    //self.calculafretenovo(self.cepId,self.pref.postalcode,self.pesototal)
            })
            .catch(function (err) {
                console.log(err);
            });

            },

        },

        directives: {mask}  
    };
</script>
