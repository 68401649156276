<template>
    <div class="container-fluid clearfix text-center checkout-finalization" style="position: fixed; top: 0; left: 0;  background-color: #f4f6f8;  z-index: 10000;  width: 100%;  height: 100vh; background-color:#f8f9fa">
        
        <div class="flex around finalization-pix">
<div class="container-title">
<div class="-container">
<h1 class="ctx-title bold mb15 mt30">
Quase lá...
</h1>

<p class="-description">
Pague seu Pix dentro de
<span class="time-left js-time-left bold values" data-seconds-left="1798">{{contador}}</span><br>
para garantir sua compra.
</p>

<div class="-loader js-check-payment" data-sale-id="116824537">
Aguardando pagamento
<svg viewBox="0 0 36 8" fill="#BF9500">
<circle cx="4" cy="4" r="4">
<animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin=".1"></animate>
</circle>
<circle cx="18" cy="4" r="4">
<animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin=".2"></animate>
</circle>
<circle cx="32" cy="4" r="4">
<animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin=".3"></animate>
</circle>
</svg>
</div>
</div>
</div>

<div class="qr-code">
<div class="-holder">
<div class="medium c-text -desktop -real">
<div class="-top-info f15 mb40">
Abra seu aplicativo de pagamento onde você utiliza o Pix e escolha a opção
<span class="inline-block bold yampi-purple">Ler QR Code</span>
</div>
<div class="flex all-center f14 black-60">
<i class="fa fa-mobile mr10"></i>
<span class="-nowrap f14">Aponte a câmera do seu celular</span>
</div>
</div>
<img :src="pix.qr_codes[0].links[0].href" style="max-height:200px;width:auto;" alt="QR Code PIX" class="-desktop -real">

<div class="price-total mt3">
<span class="-text black-80">Valor do Pix:</span>
<span class="-value bold"> R$ {{total.replace('.',',')}}</span>
</div>

<div class="mobile-description -mobile -real black-80 f14 medium">
<div class="mt25 mb10">
Após copiar o código, abra seu aplicativo de pagamento onde você utiliza o Pix.
</div>

<div>
Escolha a opção
<span class="yampi-purple bold">Pix Copia e Cola</span>
e insira o código copiado
</div>
</div>


</div>

<div class="-helper -real black-60">
<div class="f13 -text">
Você também pode pagar escolhendo a opção <br>
<span class="medium">Pix Copia e Cola</span>
no seu aplicativo de pagamento ou Internet Banking (banco online).
Neste caso, copie o código clicando no botão abaixo:
</div>

<div class="inline-block">
<input class="form-control" type="text" id="copiarpix" :value="pix.qr_codes[0].text" readOnly/>
<Button class="btn btn-secondary btn-block js-copy-paste medium f15 relative mt10 flex all-center" @click="onCopiarPix()">
    <i class="icon icon-copy-paste -dark mr15"></i>Copiar Código
</Button>
</div>
</div>
</div>
</div>
</div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import swal from 'sweetalert'
    export default {
        name: 'TodoCardEndPix',

        props: {
            todo: {
                type: Object,
                default: () => ({}),
            },
        },

        computed: {
            ...mapState({
            login: state => state.counter.login,      
            total: state => state.counter.root.total,
            emailtoken: state => state.counter.pagseguro.email,
            pix: state => state.counter.pagseguro.pix        
            }),

        },

        created() {
            this.relogio();
        },

        data() {
            return {
                min: 30,
                seg: 0,
                contador: '30:00',
                email: this.todo.email
            };
        },

        methods: {
            		
            relogio(){	
                if((this.min > 0) || (this.seg > 0)){				
                    if(this.seg == 0){					
                        this.seg = 59;					
                        this.min = this.min - 1	
                    }				
                    else{					
                        this.seg = this.seg - 1;				
                    }				
                    if(this.min.toString().length == 1){					
                        this.min = "0" + this.min;				
                    }				
                    if(this.seg.toString().length == 1){					
                        this.seg = "0" + this.seg;				
                    }				
                    this.contador = this.min + ":" + this.seg;
                    //document.getElementById('spanRelogio').innerHTML = this.min + ":" + this.seg;				
                    setTimeout(this.relogio(), 1000);			
                }			
                else{
                    this.contador = "30 minutos";
                    //document.getElementById('spanRelogio').innerHTML = "00:00";			
                }		
            },	

            onCopiarPix() {
            
            var copyText = document.getElementById("copiarpix");
            copyText.select();
            copyText.setSelectionRange(0, 99999); /* For mobile devices */
            document.execCommand("copy");

            swal("Copiado com sucesso!", {
                icon: "success",
                timer: 1000
                });

            },

            onUpdate() {
                this.$emit('update');
            },
        },
    };
</script>
