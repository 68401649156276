<template>
    <div class="container-fluid clearfix text-center checkout-finalization" style="position: fixed; top: 0; left: 0;  background-color: #f4f6f8;  z-index: 10000;  width: 100%;  height: 100vh;  padding-top: 80px;">
        
        <h1 class="t1 ctx-title bold mb15 mt30">
            Seu pedido foi realizado.
    </h1>

<p class="t2 ctx-c-description f16">
Daqui a pouco você receberá um e-mail no endereço
<span class="bold">{{login.email}}</span>
com todos os detalhes de sua compra.
</p>

<div class="holder-buttons mt20 mb40">
<a :href="`https://api.whatsapp.com/send?phone=55${login.phone}&amp;text=Aqui está o boleto que gerei da sua compra, no valor de R$ ${total.replace('.',',')} Código de barras: ${bullet.charges[0].payment_method.boleto.barcode} Link: ${bullet.charges[0].links[0].href}`" class="btn btn-primary btn-large mr10 ml10 mb20" target="_blank">
<i class="fa fa-whatsapp"></i>
Enviar pelo WhatsApp
</a>

<button @click="onOpenBullet()" class="btn btn-secondary btn-large btn-mh50 mr10 ml10 mb20">
<i class="fa fa-print"></i>
Imprimir boleto
</button>

</div><!-- /.holder-buttons -->


    <div class="alert alert-warning mb20">
<h2>Instruções</h2>
<ul class="mb15 mt20">
<li class="mb5">1. Imprima seu boleto e pague-o no banco</li>
<li class="mb5">2. Você também pode pagar pela internet usando o código de barras:</li>
</ul>
                    <div class="clearfix">
<div class="billet-barcode text-left mt10 mb15">
<label class="f11 black-70 bold title-barcode"><i class="fa fa-barcode mr5 f12"></i> CÓDIGO DE BARRAS DO BOLETO</label>

<div class="barcode mt10" style="margin-bottom: 15px;">

    <input class="form-control barcode-number bold black-80" type="text" id="copiarbarcode" :value="bullet.charges[0].payment_method.boleto.barcode" readOnly/>
    <Button
            className="btn btn-xxs btn-copy-barcode btn-secondary"
            @click="onCopiarBarCode()"
        >&nbsp; <i class="fa fa-copy f13 mr5"></i>COPIAR
    </Button>

</div><!-- /.barcode -->

<ul class="mt15 list-banks">
<li class="pull-left"><a href="http://www.bb.com.br" target="_blank" class="bank-icon"><img src="https://github.bubbstore.com/formas-de-pagamento/banco-do-brasil.svg" alt="Banco do Brasil" height="30"></a></li>
<li class="pull-left ml5"><a href="http://www.itau.com.br" target="_blank" class="bank-icon"><img src="https://github.bubbstore.com/formas-de-pagamento/itau.svg" alt="Itaú" height="30"></a></li>
<li class="pull-left ml5"><a href="http://www.bradesco.com.br" target="_blank" class="bank-icon"><img src="https://github.bubbstore.com/formas-de-pagamento/bradesco.svg" alt="Bradesco" height="30"></a></li>
<li class="pull-left ml5"><a href="http://www.santander.com.br" target="_blank" class="bank-icon"><img src="https://github.bubbstore.com/formas-de-pagamento/santander.svg" alt="" height="30"></a></li>
<li class="pull-left ml5"><a href="http://www.caixa.com.br" target="_blank" class="bank-icon"><img src="https://github.bubbstore.com/formas-de-pagamento/caixa.svg" alt="Caixa" height="30"></a></li>
</ul>
</div><!-- /.billet-barcode -->
</div><!-- /.clearfix -->                                                    </div><!-- /.alert alert-warning -->
                    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import swal from 'sweetalert'
    export default {
        name: 'TodoCardEndBullet',

        props: {
            todo: {
                type: Object,
                default: () => ({}),
            },
        },

        computed: {
            ...mapState({
            login: state => state.counter.login,      
            total: state => state.counter.root.total,
            emailtoken: state => state.counter.pagseguro.email,
            bullet: state => state.counter.pagseguro.bullet        
            }),

            tweakwiseLayeredNavigationAttributes() {
            return this.$store.state.counter.pagseguro.bullet;
            },
        },

        data() {
            return {
                email: this.todo.email
            };
        },

        methods: {

            onCopiarBarCode() {
            
            var copyText = document.getElementById("copiarbarcode");
            copyText.select();
            copyText.setSelectionRange(0, 99999); /* For mobile devices */
            document.execCommand("copy");

            swal("Copiado com sucesso!", {
                icon: "success",
                timer: 1000
                });

            },

            onOpenBullet() {
                window.open(this.bullet.charges[0].links[0].href);
            },

            onUpdate() {
                this.$emit('update');
            },
        },
    };
</script>
