export default {
  namespaced: true,
  state: () => ({
    posts: [
      { id: 1, title: 'Hello' },
      { id: 2, title: 'Bye' },
    ],
  }),
  getters: {
    // getPostById(state) {
    //   return function (id) {
    //     return state.posts.find(o => o.id === id);
    //   }
    // },

    getPostById: (state) => (id) => {
      return state.posts.find(o => o.id === id);
    }
  }
}
