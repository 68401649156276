<template>
  <div class="bloco" style="background-color: #607D8B;  padding: 10px;  border-radius: 10px;">
    <div class="row">
      <div class="col-md-12 py-3">
  <select @change="onChangeVersoes($event)" v-model="versao" class="form-control">
    <option value="0" >Selecione uma versão</option> 
    <option v-for="v in $store.state.counter.versoes" :key="v.vrs_id.id" :value="v.vrs_id">
      {{ v.vrs_nome }}
    </option>
 </select>
</div>
<div class="col-6">
 <select @change="onChangeTestamentos($event)" v-model="testamento" class="form-control">
  <option value="0">Selecione um testamento</option> 
  <option v-for="v in $store.state.counter.testamentos" :key="v.tes_id" :value="v.tes_id">
     {{ v.tes_nome }}
   </option>
</select>
</div>
<div class="col-6">
 <select @change="onChangeLivros($event)" v-model="livro" class="form-control">
  <option value="0">Selecione um livro</option> 
  <option v-for="v in $store.state.counter.livros" :key="v.liv_id" :value="v.liv_id">
     {{ v.liv_nome }}
   </option>
</select>
</div>
<div class="col-md-12 py-3">
 <select @change="onChangeCapitulos($event)" v-model="capitulo" class="form-control">
  <option value="0" >Selecione um capitulo</option>
  <option v-for="v in $store.state.counter.capitulos" :key="v" :value="v">{{v}}</option>
</select>
</div>
</div>
</div>
<div class="col-md-12">
  <ul style="list-style: none;text-align: left;padding: 0;">
    <li v-for="v in $store.state.counter.versiculos" :key="v.ver_id" :value="v.ver_id" style="margin:5px 0; padding: 10px 0; border-bottom: black 1px solid;">{{ v.ver_versiculo }}. {{ v.ver_texto }}</li>
  </ul>
</div>  
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'Counter',

  data() {
    return {
      versao:0,
      testamento:0,
      livro:0,
      capitulo:0,
      capitulos:0,
    };
  },

  created() {
    const versoes = this.$store.dispatch('counter/getVersoes', { type: 'INCREMENT', value: 1 })
  },

  computed: {
    ...mapState({
      counter: state => state.counter.counter
    })
  },

  // $store.commit('counter/INCREMENT')
  // $store.dispatch('counter/counter')

  methods: {
    ...mapMutations('counter', {
      $_add: 'INCREMENT',
      $_remove: 'DECREMENT',
    }),

    ...mapActions('counter', {
      $_counter: 'counter'
    }),

    increment() {
      // this.$store.commit('counter/INCREMENT', 1)
      this.$_add(1)
    },

    onChangeVersoes(event) {
            console.log(event.target.value)
            this.$store.dispatch('counter/getTestamentos', { type: 'INCREMENT', value: 1 })
            this.testamento = 0;
            //console.log(this.$store.state.counter.versoes)
    },

    onChangeTestamentos(event) {
            console.log(event.target.value)
            this.$store.dispatch('counter/getLivros', { testamento: this.testamento })
            this.livro = 0;
            //console.log(this.$store.state.counter.versoes)
    },

    onChangeLivros(event) {
            console.log(event.target.value)
            this.$store.dispatch('counter/getCapitulos', { versao: this.versao, livro: this.livro })
            this.capitulo = 0;
            //console.log(this.$store.state.counter.versoes)
    },

    onChangeCapitulos(event) {
            console.log(event.target.value)
            this.$store.dispatch('counter/getVersiculos', { versao: this.versao, livro: this.livro, capitulo: this.capitulo })
            //console.log(this.$store.state.counter.versoes)
    },

    decrement() {
      this.$_remove(1)
    }
  },
};
</script>
