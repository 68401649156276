<template>
    <div class="box-checkout box-addresses active" data-url="checkout/address" data-target=".box-addresses">
        <div class="box-title">
            <div class="holder-number">2</div><!-- /.holder-number -->
            <div class="title f-h2 ctx-title bold">
                Entrega
                                <i class="icon-complete ml5 fa fa-check green"></i>
                        </div><!-- /.f-h2 -->
            <div class="desc mb5 block">
                Cadastre ou selecione um endereço
            </div>
        </div><!-- /.box-title -->
                <button @click="onNovo()" class="link-box btn-create-address mt20 mb8 btn btn-tertiary btn-hp0">
                + Novo endereço
                </button>
    
                <div class="box-content">
    
                <form id="form-checkout-shipment" action="javascript:" data-update="" data-store="customers/202214269/addresses" data-url="shipping/zipcode" class="form-horizontal clearfix text-left form-address hide" method="POST">
                <div class="clearfix">
                <a href="javascript:" class="btn-close-create-address ml10 mt20 mb5 btn btn-tertiary btn-hp0">
                    <i class="fa fa-angle-left bold mr5"></i> Voltar
                </a>
            </div><!-- /.clearfix -->
            <div class="clearfix group-first mt5">
            <div class="form-group no-float form-group-zipcode">
                <label for="zipcode" class="label-control block">
                    CEP
                </label>
    
                <div class="holder-input holder-input-zipcode invalid">
                    <input type="tel" name="zipcode" id="zipcode" data-testid="zipcode-input" class="input input-validate required zipcode minlength" minlength="9" autofocus="" value="" maxlength="9">
                    <span class="spinner spinner-grey spinner-form"></span>
                </div><!-- /.holder-input -->
                <div id="zipcode_errors" class="error-block"></div><!-- /.error-block -->
            </div><!-- /.form-group w-50 -->
    
            <div class="form-group w-100 no-float blocked-shipping">
                <div class="blocked_region none" id="blocked_region_alert" style="display: none;">
        Que pena! Nossa loja ainda não realiza entregas na sua região.
    </div>		</div>
    
            <div class="form-group form-group-address">
                <div class="city-infos hide black-60 f13">
                    <span class="city-name"></span>
                    /
                    <span class="city-uf"></span>
                </div>
            </div><!-- /.form-group w-50 -->
    
        </div><!-- /.clearfix -->
    

    </form>
    
                <div class="container-addresses">
                                        <div class="box-address" :class="contact.selected ? 'selected' : ''" @click.prevent="selectContact(contact, index)" v-for="(contact, index) in contacts" v-bind:key="contact.id">
                            <label :for="`address-${contact.id}`" class="inner-box holder-icheck">
                                <div class="iradio_minimal" :class="contact.selected ? 'checked' : ''" style="position: relative;">
                                <input type="radio" name="address" :id="`address-${contact.id}`" class="input-icheck select-customer-address" :value="`${contact.postalcode}`" checked="" data-url="cart/address" style="position: absolute; visibility: hidden;"><ins class="iCheck-helper" style="position: absolute; top: 0%; left: 0%; display: block; width: 100%; height: 100%; margin: 0px; padding: 0px; background: rgb(255, 255, 255); border: 0px; opacity: 0;"></ins></div>
                                <span class="inner-label black-80 f11">
                                     <span class="medium">{{contact.street}}, N.{{contact.number}} - {{contact.district}}</span> <br>
                                    {{`${contact.city}-${contact.state}`}} | CEP <span class="zipcode">{{contact.postalcode}}</span> | Destinatário <span class="zipcode">{{contact.recipient}}</span>
                                </span>
                            </label>
                            <a href="javascript:" @click.prevent="editContact(contact)" class="btn-edit-address f12 black-40 text-center" data-url="customers/202214269/addresses/170931261">
                                <span class="fa fa-pencil"></span>
                                <span class="btn-label -mobile -block bold f9 mt4 black-40">Editar</span>
                                <span class="-desktop">
                                    <span class="tooltip2">
        <div class="tt-content-holder" style="width: auto">
            <div class="tt-content bold text-center">
                                <div class="tt-line">
                        Editar
                    </div>
                        </div>
        </div>
    </span>							</span>
                            </a>
    
                            <a href="javascript:" @click.prevent="removeContact(contact.id)" class="btn-delete-address f12 black-40 text-center" data-url="customers/202214269/addresses/170931261">
                                <span class="fa fa-trash"></span>
                                <span class="btn-label -mobile -block bold f9 mt4 black-40">Excluir</span>
                                <span class="-desktop">
                                    <span class="tooltip2">
        <div class="tt-content-holder" style="width: auto">
            <div class="tt-content bold text-center">
                                <div class="tt-line">
                        Excluir
                    </div>
                        </div>
        </div>
    </span>							</span>
                            </a>
    
                            <div class="overlay-spinner overlay-spinner-address">
                                <div class="spinner spinner-grey"></div><!-- /.spinner spinner-grey -->
                            </div><!-- /.spinner-box -->
    
                        </div><!-- /.box-address -->
                        
                    
                                        <div class="container-shipment">
                            <hr>
                            <div class="black-80 mt15 mb15" v-if="fretes.length">Escolha uma forma de entrega:</div><!-- /.black-70 -->
                            <div class="shipment-options" :class="frete.selected ? 'selected' : ''" @click.prevent="selectFrete(frete, index)" v-for="(frete, index) in fretes" v-bind:key="frete.id">
                                        <label :for="`frete-${frete.index}`" class="option clearfix">
                                        <div class="iradio_minimal" :class="frete.selected ? 'checked' : ''" style="position: relative;">
                                            <input type="radio" :id="`frete-${frete.id}`" name="shipment-service" class="input-icheck select-shipment-service" :value="frete.name" v-model="freteatual" style="position: absolute; visibility: hidden;"><ins class="iCheck-helper" style="position: absolute; top: 0%; left: 0%; display: block; width: 100%; height: 100%; margin: 0px; padding: 0px; background: rgb(255, 255, 255); border: 0px; opacity: 0;"></ins></div>
                                        <div class="inner-label">
                                            <div class="text pull-left black-80 f12">
                                                <img :src="frete.company.picture" style="max-width:100px;height:auto;"/>
                                                <span class="medium block">{{frete.name}}</span>
                                                <span class="shipping-time">entrega em até {{frete.delivery_time}} dias</span>
                                                                                        </div>
                                            <div class="price pull-right f12 bold">
                                                {{frete.currency}} {{frete.price.replace('.',',')}}
                                                                                        </div><!-- /.pull-right -->
                                        </div>
                                        <div class="overlay-spinner overlay-spinner-service">
                                            <div class="spinner spinner-grey"></div><!-- /.spinner spinner-grey -->
                                        </div><!-- /.spinner-box -->
                                    </label>
                                                        </div><!-- /.shipment-options -->
    
                                                </div><!-- /.container-shipment -->
                    
                    <div class="mt25">
                        <button @click="onEnd()" class="btn btn-primary btn-block btn-send link-box-checkout " data-target=".box-payment" data-testid="submit-cep-address" data-url="checkout/payment">
                            Continuar
                            <svg width="17" height="13" viewBox="0 0 17 13" fill="white" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.4913 0.083736L8.9516 1.66506C8.84623 1.7729 8.84652 1.94512 8.95215 2.05271L11.5613 4.71372L0.277266 4.71372C0.124222 4.71372 -3.2782e-07 4.83794 -3.21005e-07 4.99098L-2.22234e-07 7.20921C-2.1542e-07 7.36225 0.124222 7.48648 0.277266 7.48648L11.5613 7.48648L8.95216 10.1475C8.84678 10.2551 8.84652 10.427 8.9516 10.5348L10.4913 12.1162C10.5435 12.1699 10.615 12.2002 10.6899 12.2002C10.7647 12.2002 10.8363 12.1697 10.8884 12.1162L16.5579 6.29335C16.6103 6.23958 16.6366 6.16968 16.6366 6.10008C16.6366 6.03022 16.6103 5.96062 16.5579 5.90655L10.8884 0.083736C10.8363 0.0302186 10.7647 4.91753e-07 10.6899 4.94966e-07C10.615 4.98178e-07 10.5435 0.0302186 10.4913 0.083736Z"></path>
    </svg>
    
                        </button>
                    </div><!-- /.form-group -->
    
                </div><!-- /.container-addresses -->
    
            </div><!-- /.box-content -->
            <div class="overlay-spinner overlay-spinner-box">
            <div class="spinner spinner-grey"></div><!-- /.spinner spinner-grey -->
        </div><!-- /.spinner-box -->
    </div>
</template>

<script>
  import { mapState, mapMutations, mapActions } from 'vuex'
  import swal from 'sweetalert'
  import Field from '../field'
  import TheMask from 'vue-the-mask'
  import {mask} from 'vue-the-mask'
  import axios from 'axios'
  import {watch} from "vue";

import httpClient from "@/services/HttpClient.js";
    export default {
        name: 'TodoCardUpdate',

        props: {
            todo: {
                type: Object,
                default: () => ({}),
            },
        },

        computed: {
            ...mapState({
            root: state => state.counter.root,
            login: state => state.counter.login, 
            contacts: state => state.counter.contacts, 
            contactAtual: state => state.counter.contact, 
            }),

            code () {
            return `<the-mask mask="${this.mask}" value="${this.value}" type="${this.type}" masked="${this.masked}" placeholder="${this.placeholder}"></the-mask>`
            }            
        },

        data() {
            return {
                fretes: [],
                frete: {
                    selected: false,
                },
                contact: {
                    selected: false,
                },
                finalizado: false,
                freteatual: '',             
                masked: false,
                iban: 'BR0500000000011870000713973C1',
                vehicle: 'KNDJB723025140702',
                hexTokens: {
                    F: {
                    pattern: /[0-9a-fA-F]/,
                    transform: v => v.toLocaleUpperCase()
                    }
                },              
                type: 'text',
                placeholder: 'test your mask here',
                mask: '#XSAa',
                value: '12TgB',
                directive: `<input type="tel" v-mask="'##/##/####'" />`,                
                email: this.todo.email,
                name: this.todo.name,
                cpf: this.todo.cpf,
                phone: this.todo.phone,
                description: null,
            };
        },

		created () {
            this.$store.dispatch('counter/getContacts', { type: 'LOGIN',  value : this.login.id });  
		},

        methods: {

            removeContact(contactId) {

            var self=this;
            axios
            .delete(`${this.root.url}api/address/`+contactId)
            .then(function (response) {
                self.$store.dispatch('counter/getContacts', { type: 'LOGIN',  value : this.login.id });
                    
            })
            .catch(function (err) {
                console.log(err);
            });

            },

            editContact(contact) {
            this.$store.dispatch('counter/setContactId', { type: 'ENTREGA',  value: contact.id });
            this.$store.dispatch('counter/setContact', { type: 'ENTREGA',  value : {id: contact.id, street: contact.street, number: contact.number, complement: contact.complement, district: contact.district, postalcode: contact.postalcode, city: contact.city, state: contact.state, recipient: contact.recipient, country: 'BRA', selected: true} });
            this.$store.dispatch('counter/setState', { type: 'ENTREGA', value: 'show' });
            this.$store.dispatch('counter/setisEdit', { type: 'ENTREGA', value: true });
            this.$store.dispatch('counter/setHideAddress', { type: 'ENTREGA', value: false });            
            },

            selectContact(contact, index) {
            this.$store.dispatch('counter/setContactSelected', { type: 'ENTREGA',  value : {id: contact.id, selected: true} });
            this.$store.dispatch('counter/setContact', { type: 'ENTREGA',  value : {id: contact.id, street: contact.street, number: contact.number, complement: contact.complement, district: contact.district, postalcode: contact.postalcode, city: contact.city, state: contact.state, recipient: contact.recipient, country: 'BRA', selected: true} });
            this.calculafretenovo(this.root.cep,this.contacts[index].postalcode,this.root.totalweight)
            this.contact = contact;
            },

            selectFrete(frete, index) {
            this.frete = frete;
            this.frete.selected = true;
            Object.keys(this.fretes).forEach(key => this.fretes[key].selected = false);
            this.fretes[index].selected = true;
            this.$store.dispatch('counter/setFrete', { type: 'FRETE',  value : {name: this.fretes[index].name, price: this.fretes[index].price, delivery_time: this.fretes[index].delivery_time} });
            this.$store.dispatch('counter/updateTotal', { type: 'ROOT' });
            this.finalizado = true;    
            },

            calculafretenovo(ceporigem,cepdestino,peso) {
            //var destino = $("#cep").val();
            const profile = {ceporigem: ceporigem, cepdestino:cepdestino, peso: peso};

            var self=this;
            axios
                .post(`${this.root.url}api/calculafrete`, { ceporigem: ceporigem, cepdestino: cepdestino, peso: peso })
                .then(function (data, status) {

                var dados = data.data;
                self.fretes = [];

                    function executar(valor) {

                        if (!valor.error) {
                            self.fretes.push(valor);
                        }

                    }

                    //self.valorfrete = 10;// parseFloat(dados.data.valor.replace(',','.'));
                    dados.forEach(executar);
                
            }).catch(function (err) {
                    console.log(err);
                });

            },

            onNovo() {
                this.$emit('cancel');
            },

            onCancel() {
                this.todo.email = this.email;
                this.$emit('cancel');
            },

            onEnd() {

                if (this.finalizado) {
                this.$store.dispatch('counter/setContact', { type: 'ENTREGA', value: this.contact });
                this.$emit('end');
                } else {
                swal("Selecione o endereço e o frete!", {
                    icon: "error",
                    timer: 2000
                    }); 
                }

            },

            onEndi() {
                this.todo.email = this.email;
                this.todo.name = this.name;
                this.todo.cpf = this.cpf;
                this.todo.phone = this.phone;
                this.$emit('end');
            },

        },

        directives: {mask}        
    };
</script>
