<template>
    <div class="payment payment-trigger billet box-8" :class="selecionado ? 'selected' : ''" @click.prevent="$emit('updatePay')" data-affiliation="mercadopago">
        <label for="payment-billet" class="payment-header block clearfix">
            <div class="holder-icheck pull-left">
                <div class="iradio_minimal" :class="selecionado ? 'checked' : ''" style="position: relative;"><input type="radio" id="payment-billet" name="payment-type" data-name="payment-type" value="9" data-alias="billet" class="select-payment" data-url="cart/payment" style="position: absolute; visibility: hidden;"><ins class="iCheck-helper" style="position: absolute; top: 0%; left: 0%; display: block; width: 100%; height: 100%; margin: 0px; padding: 0px; background: rgb(255, 255, 255); border: 0px; opacity: 0;"></ins></div>
                <label for="payment-billet" class="holder-label medium f14 black-80">
                    <i class="icon icon-barcode"></i>
                    <span class="name">Boleto</span>
                </label>
            </div>

                        
        </label>
        <div class="payment-content">
            <div class="billet-desc mt5 mb20 f11 black-80">
                <span class="medium f13">
                                            Somente quando recebermos a confirmação, em até 48h após o pagamento, seguiremos com o envio das suas compras. O prazo de entrega passa a ser contado somente após a confirmação do pagamento.
                                    </span>
            </div><!-- /.mt10 -->

            <div v-if="bullet">
                <button id="urlpec" target="_blank" class="btn btn-primary btn-block" @click="onOpenBullet()">Clique aqui para gerar o boleto</button>
            </div>

            <div v-if="!bullet" class="billet-price f16 bold mb10">
                Valor no boleto: <span class="cart-total js-cart-total">R$ {{total.replace('.',',')}}</span>
            </div>
            
            <div class="js-orderbumps">
        </div>
            
            
            <div class="mt20">
                <button v-if="!bullet" @click="onPayBullet()" class="btn btn-primary btn-block btn-send btn-finalize with-icon" style="display: flex; justify-content: center; align-items: center;" data-testid="buy-now-billet">
                    Comprar agora

                    <svg v-if="spinner" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" class="inline-block mx-1" width="25px" height="25px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                        <circle cx="50" cy="50" fill="none" stroke="#bee3f8" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                        </circle>
                        </svg>

                </button>
            </div><!-- /.form-group -->			

        </div><!-- /.payment-content -->
        <div class="overlay-spinner overlay-spinner-payment">
            <div class="spinner spinner-grey"></div><!-- /.spinner spinner-grey -->
        </div><!-- /.spinner-box -->				
        </div><!-- /.payment -->

        <BulletFinal v-if="bullet"/>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import BulletFinal from '@/components/Pagamento/BullletEnd.vue'

    export default {
        name: 'TodoCardEnd',

        components: {
            BulletFinal,
        },

        props: {
            selecionado: false,                       
            updatePay: Function,
        },

        data() {
            return {
                spinner: false,
            };
        },


        computed: {
            ...mapState({
            login: state => state.counter.login,      
            total: state => state.counter.root.total,
            emailtoken: state => state.counter.pagseguro.email,
            bullet: state => state.counter.pagseguro.bullet        
            }),

            tweakwiseLayeredNavigationAttributes() {
            return this.$store.state.counter.pagseguro.bullet;
            },
        },

        watch: {
            // on every tweakwiseLayeredNavigationAttributes change we call fetchProducts
            tweakwiseLayeredNavigationAttributes: {
            handler(newValue, oldValue) {
                //this.fetchProducts(newValue);
                //this.onUpdate();
            },
            deep: true, // necessary for watching Arrays, Object
            immediate: true, // will be fired like inside mounted()
            }
        },

        methods: {  
            
            async fetchProducts(params) {
            console.log(params);    
            //const products = await axios.get('/api', params);
            //this.products = products;
            },

            onOpenBullet() {
                window.open(this.bullet.charges[0].links[0].href);
            },

            onPayBullet() {

            this.spinner = true;

            var data = {
            'amount': this.total,
            'cel'   : this.login.phone,
            'cpf'   : this.login.cpf.replace(/[^a-zA-Z0-9]/g, ''),
            'email' : this.login.email,
            'emailtoken' : this.emailtoken,
            'first_name' : this.login.name,
            'hash'  : 0,
            'last_name' : "",
            'payment_method_id' : "boleto",
            'product' : "Pedido",
            'product_id' : Math.floor(Math.random() * 100000) + '' + Date.now(),
            'user_id' : null,            
            };
            

            this.$store.dispatch('counter/payBullet', { type: 'PAGAMENTO', data });
            this.$emit('enviarMensagem');
            },

            async onUpdate() {
                this.$emit('update');
            },

        },
    };
</script>
