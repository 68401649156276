<template>
    <div class="max-w-7xl mx-auto my-2">

        <TodoCardShow
            v-if="isShowing"
            :todo="todo"
            @update="changeStateToUpdate"
        />

        <TodoCardUpdate
            v-if="isUpdating"
            :todo="todo"
            @end="changeStateToEnd"
            @cancel="changeStateToShow"
        />
      
        <TodoCardEnd
            v-if="isEnding"
            :todo="todo"
            @update="changeStateToUpdate"
        />

    </div>
</template>

<script>
    import TodoCardShow from '@/components/Pagamento/TodoCardShowPagamento.vue';
    import TodoCardUpdate from '@/components/Pagamento/TodoCardUpdatePagamento.vue';
    import TodoCardEnd from '@/components/Pagamento/TodoCardEndPagamento.vue';
    import { mapState, mapMutations, mapActions } from 'vuex'

    export default {
        name: 'TodoCard',

        components: {
            TodoCardShow,
            TodoCardUpdate,
            TodoCardEnd,
        },

        props: {
            todo: {
                type: Object,
                default: () => ({}),
            },
        },

        data() {
            return {
                
            };
        },

        computed: {

            ...mapState({
            mensagem: state => state.counter.mensagementrega
            }),   

            isShowing() {
                return this.todo.state === 'show';
            },

            isUpdating() {
                return this.todo.state === 'update';
            },

            isEnding() {
                return this.todo.state === 'end';
            },

        },

        methods: {
            changeStateToShow() {
                this.$store.dispatch('counter/setState', { type: 'PAGAMENTO', value: 'show' })
                //this.todo.state = 'show';
            },

            changeStateToUpdate() {
                this.$store.dispatch('counter/setState', { type: 'PAGAMENTO', value: 'update' })
                //this.todo.state = 'update';
            },

            changeStateToEnd() {
                this.$store.dispatch('counter/setState', { type: 'PAGAMENTO', value: 'end' })
                //this.todo.state = 'end';
            },

        },
    };
</script>
