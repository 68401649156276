<template>
    <ul class="steps-checkout mb20 clearfix hide ">
        <li class="checkout-step" :class="this.area == 0 ? 'enabled active' : 'disabled'">
          <div class="connect"></div>
          <div class="holder-icon">1</div>
          <div class="info">Informações pessoais</div>
          <a href="javascript:" data-url="checkout" class="link-abs active" data-target=".box-checkout"></a>
        </li>
  
                <li class="checkout-step" :class="this.area == 1 ? 'enabled active' : 'disabled'">
            <div class="connect"></div>
            <div class="holder-icon">2</div>			
            <div class="info">Entrega</div>
            <a href="javascript:" data-url="checkout/address" class="link-abs disabled" data-target=".box-checkout"></a>
          </li>
        
        <li class="checkout-step" :class="this.area == 2 ? 'enabled active' : 'disabled'">
          <div class="connect"></div>	
          <div class="holder-icon">
            3
          </div>			
          <div class="info">Pagamento</div>
          <a href="javascript:" data-url="checkout/payment" class="link-abs " data-target=".box-checkout"></a>
        </li>		
      </ul><!-- /.steps -->	
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

    export default {
        name: 'TodoCardStep',

        props: {
            todo: {
                type: Object,
                default: () => ({}),
            },
        },

        computed: {
            ...mapState({
            area: state => state.counter.area,
            }),
         
        },

		created () {
			//console.log(this.root);
		},

        data() {
            return {
            };
        },

        methods: {
        },
    };
</script>
