<template>
    <div class="box-checkout box-addresses" :class="this.area == 1 ? 'active' : 'disabled'" data-url="address" data-target=".box-addresses">
        <div class="box-title">
            <div class="holder-number">2</div><!-- /.holder-number -->
            <div class="title f-h2 ctx-title bold">
                Entrega
                        </div><!-- /.f-h2 -->
            <div class="desc mb5 block">
                Cadastre ou selecione um endereço
            </div>
        </div><!-- /.box-title -->
            
    
                <div class="box-content">
    
                <form id="form-checkout-shipment" action="javascript:" data-update="" data-store="customers/201823463/addresses" data-url="shipping/zipcode" class="form-horizontal clearfix text-left form-address " method="POST">
            <div class="clearfix group-first mt5">
            <div class="form-group no-float form-group-zipcode">
                <label for="zipcode" class="label-control block">
                    CEP
                </label>
    
                <div class="holder-input holder-input-zipcode invalid">
                    <input :disabled="isEdit" :readonly="isEdit" type="tel" name="zipcode" id="zipcode" placeholder="00000-000" @blur.prevent="mostraDados()" v-model="contact.postalcode" v-mask="'#####-###'" data-testid="zipcode-input" class="input input-validate required zipcode minlength" minlength="9" autofocus="" value="" maxlength="9">
                    <span class="spinner spinner-grey spinner-form"></span>
                </div><!-- /.holder-input -->
                <div id="zipcode_errors" class="error-block" style="display: none;"></div><!-- /.error-block -->
            </div><!-- /.form-group w-50 -->
    
            <div class="form-group w-100 no-float blocked-shipping">
                <div class="blocked_region none" id="blocked_region_alert" style="display: none;">
        Que pena! Nossa loja ainda não realiza entregas na sua região.
    </div>		</div>
    
            <div class="form-group form-group-address">
                <div class="city-infos hide black-60 f13">
                    <span class="city-name"></span>
                    /
                    <span class="city-uf"></span>
                </div>
            </div><!-- /.form-group w-50 -->
    
        </div><!-- /.clearfix -->
    
        <div class="group-form" :class="this.hideaddress ? 'hide' : ''">
            <div class="form-group w-100">
                <label for="street" class="label-control block">Endereço</label>
                <div class="holder-input invalid">
                    <input type="street" name="street" id="street" v-model="contact.street" class="input input-validate required street minlength" minlength="5" value="">
                </div><!-- /.holder-input -->
                <div id="street_errors" class="error-block"></div><!-- /.error-block -->
            </div><!-- /.form-group w-50 -->
    
            <div class="form-group keep-size -padding-right form-group-adrress-number">
                <label for="number" class="label-control block">Número</label>
                <div class="holder-input invalid">
                    <input type="text" maxlength="9" name="number" id="number" v-model="contact.number" class="input input-validate required" value="">
                </div><!-- /.holder-input -->
                <div id="number_errors" class="error-block"></div><!-- /.error-block -->
            </div><!-- /.form-group w-50 -->
    
            <div class="form-group keep-size -padding-left form-group-neighborhood">
                <label for="neighborhood" class="label-control block">Bairro</label>
                <div class="holder-input invalid">
                    <input type="neighborhood" name="neighborhood" id="neighborhood" v-model="contact.district" class="input input-validate required neighborhood minlength maxlength" minlength="3" maxlength="40" value="" data-error-message-maxlength="Limite de 40 caracteres foi excedido.">
                </div><!-- /.holder-input -->
                <div id="neighborhood_errors" class="error-block"></div><!-- /.error-block -->
            </div><!-- /.form-group w-50 -->
    
            <div class="form-group w-100">
                <label for="address_complement" class="label-control block">
                    Complemento <span class="f11 black-60">(opcional)</span>
                </label>
                <div class="holder-input">
                    <input type="text" name="complement" id="address_complement" v-model="contact.complement" class="input address_complement js-check" value="" maxlength="40">
                </div><!-- /.holder-input -->
                <div id="complement_errors" class="error-block"></div><!-- /.error-block -->
            </div><!-- /.form-group w-50 -->
    
            <div class="form-group mr-1">
                <label class="label-control block">Estado</label>
                <select name="state" id="state" class="form-control" v-model="contact.state" style="height: 45px;">
                    <option value="">Informe o Estado</option>
                    <option value="AC">Acre</option>
                    <option value="AL">Alagoas</option>
                    <option value="AM">Amazonas</option>
                    <option value="AP">Amapá</option>
                    <option value="BA">Bahia</option>
                    <option value="CE">Ceará</option>
                    <option value="DF">Distrito Federal</option>
                    <option value="ES">Espirito Santo</option>
                    <option value="GO">Goiás</option>
                    <option value="MA">Maranhão</option>
                    <option value="MG">Minas Gerais</option>
                    <option value="MS">Mato Grosso do Sul</option>
                    <option value="MT">Mato Grosso</option>
                    <option value="PA">Pará</option>
                    <option value="PB">Paraíba</option>
                    <option value="PE">Pernambuco</option>
                    <option value="PI">Piauí</option>
                    <option value="PR">Paraná</option>
                    <option value="RJ">Rio de Janeiro</option>
                    <option value="RN">Rio Grande do Norte</option>
                    <option value="RO">Rondonia</option>
                    <option value="RR">Roraima</option>
                    <option value="RS">Rio Grande do Sul</option>
                    <option value="SC">Santa Catarina</option>
                    <option value="SE">Sergipe</option>
                    <option value="SP">São Paulo</option>
                    <option value="TO">Tocantins</option>
               </select>
            </div>

            <div class="form-group mr-1">
                <label class="label-control block">Cidade</label>
                <input type="text" class="form-control" placeholder="Cidade" v-model="contact.city" required>
            </div>

            <div class="form-group w-100">
                <label for="recipient" class="label-control block">Destinatário</label>
                <div class="holder-input valid">
                    <input type="recipient" name="recipient" v-model="contact.recipient" id="recipient" class="input input-validate required receiver minlength fullname" minlength="3" value="">
                </div><!-- /.holder-input -->
                <div id="receiver_errors" class="error-block" style="display: none;"></div><!-- /.error-block -->
            </div><!-- /.form-group w-50 -->
    
            <div class="form-group w-100">
                <button v-if="!isEdit" class="btn btn-primary btn-block btn-send" @click.prevent="saveContact(contact)">Salvar</button>
                <button v-if="isEdit" class="btn btn-primary btn-block btn-send" @click.prevent="updateContact(contact)">Atualizar</button>
            </div><!-- /.form-group -->
    
            <input type="hidden" name="city" class="city" value="">
            <input type="hidden" name="uf" class="uf" value="">
    
        </div><!-- /.group-form -->
    
        <input type="hidden" name="_token" value="dLrgJcbhkUvewwcINFQs5WLQJgMdN17BBvCc87E4">
    </form>
    
                <div class="container-addresses hide">
                    
                    <div class="form-group w-100 no-float blocked-shipping">
                        <div class="blocked_region " id="blocked_region_alert">
        Que pena! Nossa loja ainda não realiza entregas na sua região.
    </div>				</div>
    
                    
                    
                    <div class="mt25">
                        <button type="submit" class="btn btn-primary btn-block btn-send link-box-checkout disabled" data-target=".box-payment" data-testid="submit-cep-address" data-url="checkout/payment">
                            Continuar
                            <svg width="17" height="13" viewBox="0 0 17 13" fill="white" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.4913 0.083736L8.9516 1.66506C8.84623 1.7729 8.84652 1.94512 8.95215 2.05271L11.5613 4.71372L0.277266 4.71372C0.124222 4.71372 -3.2782e-07 4.83794 -3.21005e-07 4.99098L-2.22234e-07 7.20921C-2.1542e-07 7.36225 0.124222 7.48648 0.277266 7.48648L11.5613 7.48648L8.95216 10.1475C8.84678 10.2551 8.84652 10.427 8.9516 10.5348L10.4913 12.1162C10.5435 12.1699 10.615 12.2002 10.6899 12.2002C10.7647 12.2002 10.8363 12.1697 10.8884 12.1162L16.5579 6.29335C16.6103 6.23958 16.6366 6.16968 16.6366 6.10008C16.6366 6.03022 16.6103 5.96062 16.5579 5.90655L10.8884 0.083736C10.8363 0.0302186 10.7647 4.91753e-07 10.6899 4.94966e-07C10.615 4.98178e-07 10.5435 0.0302186 10.4913 0.083736Z"></path>
    </svg>
    
                        </button>
                    </div><!-- /.form-group -->
    
                </div><!-- /.container-addresses -->
    
            </div><!-- /.box-content -->
            <div class="overlay-spinner overlay-spinner-box">
            <div class="spinner spinner-grey"></div><!-- /.spinner spinner-grey -->
        </div><!-- /.spinner-box -->
    </div><!-- /.ultimo -->
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import Field from '../field'
import TheMask from 'vue-the-mask'
import {mask} from 'vue-the-mask'
import axios from 'axios'

    export default {
        name: 'TodoCardShow',

        props: {
            todo: {
                type: Object,
                default: () => ({}),
            },
        },

        computed: {
            ...mapState({
            root: state => state.counter.root,
            area: state => state.counter.area,
            contactSave: state => state.counter.contact,
            contactId: state => state.counter.contactId,
            isEdit: state => state.counter.isEdit,
            hideaddress: state => state.counter.hideaddress,
            login: state => state.counter.login,
            entrega: state => state.counter.entrega,      
            }),

            code () {
            return `<the-mask mask="${this.mask}" value="${this.value}" type="${this.type}" masked="${this.masked}" placeholder="${this.placeholder}"></the-mask>`
            }            
        },

        mounted () {
        },

        created () {
            this.ChecaEdicao();	
        },

        data() {
            return {
                masked: false,
                iban: 'BR0500000000011870000713973C1',
                vehicle: 'KNDJB723025140702',
                hexTokens: {
                    F: {
                    pattern: /[0-9a-fA-F]/,
                    transform: v => v.toLocaleUpperCase()
                    }
                },
                contacts: [],
                contact: {
                    id: '',
                    street: '',
                    number: '',
                    complement: '',
                    district: '',
                    postalcode: '',
                    city: '',
                    state: '',
                    country: 'BRA',
                    recipient: ''
                },                
                type: 'text',
                placeholder: 'test your mask here',
                mask: '#XSAa',
                value: '12TgB',
                directive: `<input type="tel" v-mask="'##/##/####'" />`,                
                email: this.todo.email,
                name: this.todo.name,
                cpf: this.todo.cpf,
                cep: this.todo.cep,
                phone: this.todo.phone,
                description: null
            };
        },

        methods: {
            onUpdate() {
                this.todo.email = this.email;
                this.$emit('update');	
            },

            ChecaEdicao() {

                if (this.isEdit) {
                    this.contact.id = this.contactSave.id;
                    this.contact.street = this.contactSave.street;
                    this.contact.number = this.contactSave.number;
                    this.contact.complement = this.contactSave.complement;
                    this.contact.district = this.contactSave.district;
                    this.contact.postalcode = this.contactSave.postalcode;
                    this.contact.city = this.contactSave.city;
                    this.contact.state = this.contactSave.state;
                    this.contact.recipient = this.contactSave.recipient;
                    this.contact.country = 'BRA'
                } 

            },

            mostraDados() {
            var self=this;
            const cep = this.contact.postalcode;

            if (cep) {
                fetch (`https://viacep.com.br/ws/${cep}/json/`)
                .then (respostaDoServer => {
                    return respostaDoServer.json();
                })
                .then (dadosDoCep => {

                self.contact.street = dadosDoCep.logradouro;
                self.contact.district = dadosDoCep.bairro;
                self.contact.number = '';
                self.contact.state = dadosDoCep.uf;
                self.contact.city = dadosDoCep.localidade;
                self.contact.complement = dadosDoCep.complemento;
                self.contact.recipient = self.login.name;

                //self.hideaddress = false;
                self.$store.dispatch('counter/setHideAddress', { type: 'ENTREGA', value: false });
                });

                }
            },	

            saveContact(contact) {

            contact.id = null;
            contact.user_id = this.login.id;

            var self=this;
            axios
            .post(`${this.root.url}api/address`, contact)
            .then(function (response) {
                    //self.atualizaLista();
                    self.contacts.push(response.data);
                    self.setContact(response.data);
                    contact.street = '';
                    contact.postalcode = '';
                    contact.district = '';
                    contact.number = '';
                    contact.state = '';
                    contact.city = '';
                    contact.complement = '';
                    contact.recipient = '';
                    //console.log(self.contacts[0].id);
                    //self.setContact(self.contacts[0]);
                    self.hideaddress = true;
                    self.$store.dispatch('counter/setState', { type: 'ENTREGA', value: 'update' });
            })
            .catch(function (err) {
                //console.log(contact);
                console.log(err);
            });
            
            },


            updateContact(contato) {
        
                contato.id = this.contactId;
                var self=this;
                axios
                .put(`${this.root.url}api/address/`+ this.contactId, contato)
                .then(function (response) {
                        //self.atualizaLista();
                        //self.isEdit = false;
                        contato.street = '';
                        contato.postalcode = '';
                        contato.district = '';
                        contato.number = '';
                        contato.state = '';
                        contato.city = '';
                        contato.complement = '';
                        contato.recipient = '';
                        self.$store.dispatch('counter/setState', { type: 'ENTREGA', value: 'update' });
                })
                .catch(function (err) {
                    console.log(err);
                });
            
            },


            setContact(contact) {
            contact.address = contact.id;
            var self=this;
            axios
            .put(`${this.root.url}api/user/${this.login.id}`, contact)
            .then(function (response) {
                    //self.setMenu1Active();
                    var este = self.contacts.find(x => x.id === response.data.address);
                    self.pref = este;
                    
                    //self.atualizaLista();
                
                    //self.calculafretenovo(self.cepId,self.pref.postalcode,self.pesototal)
            })
            .catch(function (err) {
                console.log(err);
            });

            },

        },

        directives: {mask}  
    };
</script>
