<template>
    <div class="black-80 f14">
        
        <div class="f16 medium mb10">
           {{this.todo.name}}
        </div>
        
        <div class="mb5">Email: {{this.todo.email}}</div>
       
        <div>
            CPF
            <span class="cpf">{{this.todo.cpf}}</span>
        </div>

        <div @click="onUpdate()" class="edit" style="cursor: pointer; width: 40px; height: 40px; position: absolute; right: 5px; top: 5px;">
            <span class="fa fa-pencil" style="position: absolute; right: 5px; top: 20px; padding: 10px;"></span>
            <span class="tooltip2">
            <div class="tt-content-holder" style="width: auto">
                <div class="tt-content bold text-center">
                    <div class="tt-line">Editar</div>
                </div>
            </div>
            </span>    
        </div>

    </div>
</template>

<script>
    export default {
        name: 'TodoCardEnd',

        props: {
            todo: {
                type: Object,
                default: () => ({}),
            },
        },

        data() {
            return {
                email: this.todo.email
            };
        },

        methods: {
            onUpdate() {
                this.$emit('update');
            },
        },
    };
</script>
