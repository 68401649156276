<template>
    <div class="box-checkout box-customer link-box-checkout" data-target=".box-customer" data-url="checkout" data-next=".box-addresses" data-discount-alias="">
        <div class="box-title">
            <div class="holder-number">1</div><!-- /.holder-number -->
            <div class="title f-h2 ctx-title bold"> Identifique-se <i class="icon-complete ml5 fa fa-check green"></i> </div><!-- /.f-h2 -->
            <div class="desc mb20"> Utilizaremos seu e-mail para: Identificar seu perfil, histórico de compra, notificação de pedidos e carrinho de compras. </div></div><!-- /.box-title -->                        
                <div class="infos black-80 f14">
                <div class="f16 medium mb10">{{this.todo.name}}</div>
                <div class="mb5">Email: {{this.todo.email}}</div>
                <div> CPF: <span class="cpf">{{this.todo.cpf}}</span></div>
                <div> Telefone: <span class="phone">{{this.todo.phone}}</span></div>
                <div @click="onUpdate()" class="edit" style="cursor: pointer; width: 40px; height: 40px; position: absolute; right: 5px; top: 5px;">
                    <span class="fa fa-pencil" style="position:absolute;right:5px;top:20px;padding:10px;"></span><span class="tooltip2">
                    <div class="tt-content-holder" style="width:auto;"><div class="tt-content bold text-center">
                    <div class="tt-line">Editar</div></div></div></span></div></div><!-- /.box-content -->
                <div class="overlay-spinner overlay-spinner-box"><div class="spinner spinner-grey"></div><!-- /.spinner spinner-grey -->
            </div><!-- /.spinner-box -->
        </div>
</template>

<script>
    export default {
        name: 'TodoCardEnd',

        props: {
            todo: {
                type: Object,
                default: () => ({}),
            },
        },

        data() {
            return {
                email: this.todo.email
            };
        },

        methods: {
            onUpdate() {
                this.$emit('update');
            },
        },
    };
</script>
