export default {
  namespaced: true,

  state: () => ({
    first_name: 'Arya',
    last_name: 'Stark',
    email: 'arya@stark.com'
  }),

  mutations: {
    STORE_FIRST_NAME(state, payload) {
      state.first_name = payload
    },
  },

  action: {},

  getters: {
    fullName(state) {
      return `${state.first_name} ${state.last_name}`
    },
  }
}
