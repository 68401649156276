<template>
    <div class="col-checkout col-3">
        <div class="box-checkout box-resume opened -force-open">
  <div class="box-title flex between js-box-resume-title">
    <div class="c-text">
      <span class="-title f18 medium">
        RESUMO
      </span>
      <span class="items__count bold -mobile">
        (1)
      </span>
      <div class="resume-description f11 black-60 mt2 -mobile -block">Informações da sua compra</div>
    </div><!-- /.f-h2 -->
    <div>
      <span class="cart_total -mobile mt7">
        <span class="-total">
          R$ {{total}}
        </span>
        <i class="icon icon-arrow-left"></i>
      </span>
    </div>
  </div><!-- /.box-title -->
  <div class="js-box-animation box-animation">
    <div class="box-content clearfix mt15">
      <div class="clearfix holder-promocode" id="holder-promocode">
                <form action="cart/promocode/store" id="form-promocode" method="post" class="form-promocode form-horizontal">
    <div class="form-group w-100 d-none">
      <div class="clearfix">
        <label for="promocode" class="label-control block">
          Tem um cupom?
        </label>

        <i class="block icon-promocode"></i>

        <div class="flex">
          <input type="text" name="code" id="promocode" placeholder="Código do cupom" class="input input-promocode pull-left" value="" required="">
          <button class="btn btn-tertiary btn-h36">
            Adicionar
          </button>
        </div>
        <div class="spinner-grey spinner-promocode"></div>
      </div><!-- /.clearfix -->
      <div class="c-error f10 mt5 mb5 promocode-error"></div>

          </div><!-- /.form-group -->

    <input type="hidden" name="_token" value="qHbcDVYY49loHLa7QZT7YMR49RDPE3LCOd0K6X7g">

  </form>
      </div><!-- /.clearfix -->

      <div class="cart-resume mt10">
            <div class="detail">
            <div class="description">Produtos</div>
            <div class="value">R$ {{subtotal.replace('.',',')}}</div>
          </div>
            <div class="detail">
            <div class="description">Frete</div>
            <div class="value">R$ {{frete.replace('.',',')}}</div>
          </div>
          <div class="detail">
            <div class="description">Desconto</div>
            <div class="value">R$ {{desconto.replace('.',',')}}</div>
          </div>
                
    <div class="detail total bold">
        <div class="description">
            Total
        </div>
        <div class="value js-cart-total">
            R$ {{total.replace('.',',')}}
        </div>
    </div>
</div>
      <div class="holder-container-resume js-holder-container-resume" v-for="(item, index) in cart" v-bind:key="item.id">
            <div class="item-holder js-item-holder flex  " data-item-id="660095112" data-product-option-id="210979033" data-kit-id="" data-quantity="1" data-custom="" data-shopify-variant-id="48330761404718" data-bundle-id="" data-shopify="1" data-order-bump-id="">
    <div class="overlay-spinner overlay-spinner-box">
        <div class="spinner spinner-grey"></div><!-- /.spinner-grey -->
    </div>

    <div class="item-image">
        <img :src="item.image" alt="Batedor Mix  de Aço Inoxidável | Super Prático!" class="thumb-product block">
    </div>

    <div class="item-detail f12">
        <div class="item-row">
            <div class="item-name c-text-tertiary">{{item.name}}</div>
            <div class="item-delete"></div>
        </div>

        
        
        
        <div class="item-row item-holder-quantity-price c-text medium">
                            <span class="item-quantity">Qtd.: {{item.amount}}</span>
                        <span class="item-price"> R$ {{item.price.replace('.',',')}}</span>
        </div>

            </div>

    </div>
    
    </div><!-- /.holder-container-resume -->
    </div><!-- /.box-content -->
  </div>

  <div class="overlay-spinner overlay-spinner-box">
    <div class="spinner spinner-grey"></div><!-- /.spinner spinner-grey -->
  </div><!-- /.spinner-box -->
</div><!-- /.box-checkout -->

<div class="box-promocode clearfix" id="box-promocode">
</div><!-- /.box-promocode clearfix -->
      </div><!-- /.col-checkout -->
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import Field from '../field'
import TheMask from 'vue-the-mask'
import {mask} from 'vue-the-mask'
import axios from 'axios'

    export default {
        name: 'TodoCardShow',

        props: {
            todo: {
                type: Object,
                default: () => ({}),
            },
        },

        computed: {
            ...mapState({
            subtotal: state => state.counter.root.subtotal,  
            total: state => state.counter.root.total,
            frete: state => state.counter.frete.price,
            desconto: state => state.counter.root.discount,
            cart: state => state.counter.root.cart,      
            area: state => state.counter.area,     
            }),

            code () {
            return `<the-mask mask="${this.mask}" value="${this.value}" type="${this.type}" masked="${this.masked}" placeholder="${this.placeholder}"></the-mask>`
            }            
        },

        created () {
          //console.log(this.cart);
          //  console.log(
          //    this.cart.reduce((a, b) => parseFloat(a.price) + parseFloat(b.price))
         //   )
        },

        data() {
            return {
                masked: false,
                iban: 'BR0500000000011870000713973C1',
                vehicle: 'KNDJB723025140702',
                hexTokens: {
                    F: {
                    pattern: /[0-9a-fA-F]/,
                    transform: v => v.toLocaleUpperCase()
                    }
                },           
                type: 'text',
                placeholder: 'test your mask here',
                mask: '#XSAa',
                value: '12TgB',
                directive: `<input type="tel" v-mask="'##/##/####'" />`,                
                description: null
            };
        },

        methods: {
            onUpdate() {
                this.todo.email = this.email;
                this.$emit('update');
            },

        },

        directives: {mask}  
    };
</script>
